export class AttributeParser {
    static fromAttribute(value, type) {
        switch (type) {
            case 'boolean':
                return value !== null && value !== 'false';
            case 'number':
                return value === null ? null : Number(value);
            case 'object':
            case 'array':
                return JSON.parse(value);
        }
        return value;
    }
    static toAttribute(value, type) {
        switch (type) {
            case 'boolean':
                return value ? '' : false;
            case 'object':
            case 'array':
                return JSON.stringify(value);
        }
        return value;
    }
}
