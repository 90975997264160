import { ScriptDataAttributes } from './data-attributes';
export const selfScriptEl = document.currentScript;
export const requiredVal = selfScriptEl.getAttribute(ScriptDataAttributes.required);
export const sessionStorageKeyPrefixAttr = selfScriptEl.getAttribute(ScriptDataAttributes.sessionStorageKeyPrefix);
export const successTarget = selfScriptEl.getAttribute(ScriptDataAttributes.successTarget) || document.URL;
export const failTarget = selfScriptEl.getAttribute(ScriptDataAttributes.failTarget);
export const authWaypointTarget = selfScriptEl.getAttribute(ScriptDataAttributes.waypointTarget);
export const appClientId = selfScriptEl.getAttribute(ScriptDataAttributes.appClientID);
export const logoutUrl = selfScriptEl.getAttribute(ScriptDataAttributes.logoutURL);
export const missingInfoRequired = selfScriptEl.getAttribute(ScriptDataAttributes.missingInfo);
export const requiredMIVal = missingInfoRequired === 'false' ? null : 'true';
export const channel = selfScriptEl.getAttribute(ScriptDataAttributes.channelOverride);
export const authBrand = selfScriptEl.getAttribute(ScriptDataAttributes.brandOverride);
export const authLocale = selfScriptEl.getAttribute(ScriptDataAttributes.localeOverride);
export const loginHint = selfScriptEl.getAttribute(ScriptDataAttributes.loginHint);
export const mockEnabled = selfScriptEl.getAttribute(ScriptDataAttributes.mockEnabled) === 'true' ? true : false;
export const appPolicy = selfScriptEl.getAttribute(ScriptDataAttributes.policy);
export const appTenant = selfScriptEl.getAttribute(ScriptDataAttributes.tenant);
export const disclosureCloseLabel = selfScriptEl.getAttribute(ScriptDataAttributes.disclosureCloseButtonLabel);
export const useDataAttributes = selfScriptEl.getAttribute(ScriptDataAttributes.useDataAttributes);
export const languageDataAttribute = selfScriptEl.getAttribute(ScriptDataAttributes.languageDataAttribute);
export const countryDataAttribute = selfScriptEl.getAttribute(ScriptDataAttributes.countryDataAttribute);
export const environmentDataAttribute = selfScriptEl.getAttribute(ScriptDataAttributes.environmentDataAttribute);
