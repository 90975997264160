var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import '../../utils/event-retargeting.js';
er('gb-', 'gn-');
import { GbdsElement, prop } from '@gbds/core/src/ts/core';
import { debounce } from '@gbds/core/src/ts/utils';
import { HEIGHT_RENDER, INITIAL_RENDER, NAV_STICKY, NAV_UNSTICKY } from '../../events';
import { PortableExperiencesEvents } from '../../utils/portable-experiences';
import { FlyoutService } from '../../utils/flyout-service';
import { getNetStorageContentPath } from '../../utils/netstorage-paths';
import { parseString } from '../../utils/dom-string-parser';
import { isShiftTab, isTabOnly } from '@gbds/core/src/ts/utils';
import { getFirstAndLastFocusableElement } from '@gbds/core/src/ts/utils/firstlast-focusable-elements';
import '../global-footer';
import './flyout';
import '@gbds/core/src/ts/components/tab-nav';
import '@gbds/core/src/ts/components/expander';
import { throttle } from '@gbds/core/src/ts/utils';
import '@gbds/quantum/src/ts/components/core/responsive-image/responsive-image';
import '@gbds/quantum/src/ts/components/core/static-image/static-image';
import '@gbds/quantum/src/ts/components/core/dynamic-text/dynamic-text';
import '@gbds/quantum/src/ts/components/core/regional-price/regional-price';
import '@gbds/quantum/src/ts/components/core/regional-disclosure/regional-disclosure';
import { isMediumViewport, isSmallViewport } from '@gbds/core/src/ts/utils/viewport';
import { isWebViewApp, isRTL } from '../../../../../gbds-core/src/ts/utils/helper-utils';
import '@gbds/quantum/src/ts/components/core/button/button';
import '@gbds/quantum/src/ts/components/target-zone/target-zone';
import '@gbds/quantum/src/ts/components/core/wrapper/wrapper';
import '@gbds/quantum/src/ts/components/core/disclosure/index';
export class GlobalNav extends GbdsElement {
    constructor(brandStyles, _defaultVariation, _navConfig, _handleLanguageLinksPath) {
        super();
        this._defaultVariation = _defaultVariation;
        this._navConfig = _navConfig;
        this._handleLanguageLinksPath = _handleLanguageLinksPath;
        this._templateSuffix = '-global_nav.html';
        this._firstRender = true;
        this._lastTop = window.pageYOffset || document.documentElement.scrollTop;
        this._printStyles = `
	@media print {
		gb-global-nav, gb-global-footer, .gb-overlay {
			display: none;
		}
	}`;
        this._flyoutService = new FlyoutService(this);
        this._styles = brandStyles;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (!isWebViewApp()) {
                this._initiallySticky = this.sticky;
                this._windowInnerWidth = window.innerWidth;
                const childTemplates = this.getElementsByTagName('template');
                if (this.childElementCount !== 0 && this._firstRender && childTemplates.length > 0) {
                    yield this._renderStatic();
                }
                else if (this._firstRender) {
                    yield this._renderDynamic((_a = this.variation) !== null && _a !== void 0 ? _a : this._defaultVariation);
                }
                if (isRTL()) {
                    this.style.setProperty('--rotate-stacked-button-arrow', 'rotate(180deg)');
                    this.style.setProperty('--rotate-back-button-arrow', 'rotate(0deg)');
                }
                this._initScrollListener();
                this._handleLanguageSelector();
                if (this._handleLanguageLinksPath) {
                    yield window.gbpe.auth.loaded;
                    window.gbpe.languageService.handleLanguageLinks(this);
                }
                this._updateLogoStatClass();
                this._addAriaLabel();
                this._addNavRole();
                this._navFocusableElements = Array.from(this.shadowRoot.querySelectorAll('a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'));
                this._navFocusableElements = this._navFocusableElements.filter(el => !el.hasAttribute('disabled'));
                this._myAccountNav = this.shadowRoot.querySelector('gb-myaccount-nav');
                this._addTabListeners();
                this._addMyAccountTabListener();
                this._addMainContentShiftTabListener();
                this._addNonComposedEventListener();
            }
        });
    }
    _addNonComposedEventListener() {
        this.shadowRoot.addEventListener('change', this._reDispatchNonComposedEvent);
    }
    _reDispatchNonComposedEvent(e) {
        const newEvent = new CustomEvent(e.type, Object.assign(Object.assign({}, e), { composed: true }));
        newEvent.composedPath = () => e.composedPath();
        window.dispatchEvent(newEvent);
    }
    _addNavRole() {
        if (this.getAttribute('role') === null) {
            this.setAttribute('role', 'navigation');
        }
    }
    _addAriaLabel() {
        const ariaLabel = this.shadowRoot.querySelector('.gn-aria-label');
        if (ariaLabel && ariaLabel.getAttribute('data-aria-label') !== null) {
            this.setAttribute('aria-label', ariaLabel.getAttribute('data-aria-label'));
        }
    }
    _updateLogoStatClass() {
        const logo = Array.from(this.shadowRoot.querySelectorAll('.gn-logo-container a'));
        logo.forEach(l => {
            l.classList.remove('stat-image-link');
            l.classList.add('stat-logo');
        });
    }
    _handleLanguageSelector() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            yield window.gbpe.auth.loaded;
            const languageLinkList = Array.from(this.shadowRoot.querySelectorAll('a[mporgnav]'));
            if (window.gbpe.auth.currentAuthState === 1 && ((_a = this._navConfig) === null || _a === void 0 ? void 0 : _a.region) === 'us') {
                languageLinkList.forEach(languageLink => {
                    languageLink.style.display = 'none';
                });
            }
            const languages = {};
            languages[this._navConfig.language.toUpperCase()] = {
                languageCode: this._navConfig.language,
                url: window.location.href,
                default: true,
            };
            window.gbpe.languageService.init(languages);
            languageLinkList.forEach(languageLink => {
                if (languageLink.hasAttribute('data-lang')) {
                    const languageCode = languageLink.getAttribute('data-lang');
                    const languageURL = languageLink.getAttribute('data-href')
                        ? languageLink.getAttribute('data-href')
                        : languageLink.getAttribute('href');
                    const lang = { languageCode: languageCode, url: languageURL };
                    window.gbpe.languageService.addLanguage(lang);
                }
            });
        });
    }
    _initScrollListener() {
        var _a;
        this.showScrollHeight = (_a = this.showScrollHeight) !== null && _a !== void 0 ? _a : 150;
        this._lastTop = window.scrollY;
        document.addEventListener('scroll', throttle(this._handleScroll.bind(this), 200));
    }
    _handleScroll() {
        const newScrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (newScrollTop < this._lastTop) {
            this._handleScrollUp(newScrollTop);
        }
        else {
            this._handleScrollDown();
        }
        this._lastTop = newScrollTop <= 0 ? 0 : newScrollTop;
    }
    _handleScrollUp(newScrollTop) {
        if (!this.disableScrollUpShow) {
            if (!this._scrollUpBegin)
                this._scrollUpBegin = newScrollTop;
            const delta = this._scrollUpBegin - newScrollTop;
            if (delta >= this.showScrollHeight && !this.sticky && !this._initiallySticky) {
                this._initialBodyHeightValue = document.body.style.height;
                document.body.style.height = 'initial';
                this.sticky = true;
            }
        }
    }
    _handleScrollDown() {
        this._scrollUpBegin = null;
        if (!this._initiallySticky && !this.flyoutService.flyoutOpen && !this.flyoutService.flyoutOpening) {
            document.body.style.height = this._initialBodyHeightValue || '';
            this.sticky = false;
        }
    }
    _addTabListeners() {
        this._navFocusableElements.forEach(btn => {
            btn.addEventListener('keydown', e => {
                this._handleTabEvent(e);
                this._handleShiftTabEvent(e);
            });
        });
    }
    _addMyAccountTabListener() {
        const myAccount = this.shadowRoot.querySelector('gb-myaccount-nav');
        myAccount === null || myAccount === void 0 ? void 0 : myAccount.addEventListener('keydown', e => {
            this._handleMyAccountTabEvent(e);
        });
    }
    _addMainContentShiftTabListener() {
        const mainContent = this.appContentSelector
            ? document.querySelector(this.appContentSelector)
            : document.querySelector('main');
        const { first } = getFirstAndLastFocusableElement(mainContent);
        first === null || first === void 0 ? void 0 : first.addEventListener('keydown', e => {
            this._handleMainContentShiftTabEvent(e);
        });
    }
    _focusNextVisibleNavElement(e) {
        const isHamburgerMenuEnabled = this.shadowRoot.querySelectorAll('.gn-main-nav button.gn-main-button[data-hamburger-menu="true"]').length ||
            this.shadowRoot.querySelectorAll('.gn-main-nav a.gn-main-link.stat-image-link[data-hamburger-menu="true"]')
                .length > 0
            ? 'true'
            : 'false';
        const getMainNavLastFocusableElement = () => {
            const mainNav = this.shadowRoot.querySelector('.gn-main-nav');
            const last = mainNav.querySelector('.stacked-menu-container .gn-main-button');
            return last;
        };
        const getHBMenuMainNavLastFocusableEl = () => {
            const mainNav = this.shadowRoot.querySelector('.gn-main-nav');
            const mainNavEls = mainNav.querySelectorAll('[class*="gn-main"]:not(.hide-large)');
            const last = mainNavEls[mainNavEls.length - 1];
            return last;
        };
        const getMiscNavFirstFocusableElement = () => {
            const miscNav = this.shadowRoot.querySelector('.gn-misc');
            const { first } = getFirstAndLastFocusableElement(miscNav);
            return first;
        };
        const getLogoFocusableElement = () => {
            const logoContent = this.shadowRoot.querySelector('.gn-logo-container');
            const { first } = getFirstAndLastFocusableElement(logoContent);
            return first;
        };
        const currElement = this.shadowRoot.activeElement;
        if (isHamburgerMenuEnabled === 'true') {
            const lastLinkMainNavEl = getHBMenuMainNavLastFocusableEl();
            const skipEl = this.shadowRoot.querySelector('.gb-skip-to-main-content');
            const logoEl = this.shadowRoot.querySelector('.gn-logo-container a');
            const miscNavEl = getMiscNavFirstFocusableElement();
            const hamburgerBtn = (this.shadowRoot.querySelector('.gn-main-nav button.gn-main-button[data-hamburger-menu="true"]'));
            const primaryLinkBtn1 = (this.shadowRoot.querySelector('.gn-main-nav button.gn-main-button[flyout="primary-1"]'));
            const primaryLinkBtn2 = (this.shadowRoot.querySelector('.gn-main-nav button.gn-main-button[flyout="primary-2"]'));
            let elementToFocus;
            if (currElement === skipEl) {
                elementToFocus = hamburgerBtn;
            }
            else if (currElement === hamburgerBtn && (isSmallViewport() || isMediumViewport())) {
                elementToFocus = logoEl;
            }
            else if (currElement === lastLinkMainNavEl && !isSmallViewport() && !isMediumViewport()) {
                elementToFocus = logoEl;
            }
            else if (currElement === lastLinkMainNavEl && (isSmallViewport() || isMediumViewport())) {
                elementToFocus = this.nextElementSibling;
            }
            else if (currElement === logoEl) {
                elementToFocus = miscNavEl;
            }
            else if (currElement === miscNavEl && (isSmallViewport() || isMediumViewport())) {
                elementToFocus = primaryLinkBtn1;
            }
            else if (currElement === primaryLinkBtn1 && (isSmallViewport() || isMediumViewport())) {
                elementToFocus = primaryLinkBtn2;
            }
            if (elementToFocus) {
                elementToFocus.focus();
                e.preventDefault();
            }
        }
        if (isSmallViewport() || isMediumViewport()) {
            if (isHamburgerMenuEnabled !== 'true') {
                if (currElement === getLogoFocusableElement()) {
                    const elToFocus = getMainNavLastFocusableElement();
                    elToFocus.focus();
                }
                else if (currElement === getMainNavLastFocusableElement()) {
                    const elToFocus = this.nextElementSibling;
                    elToFocus.focus();
                }
            }
        }
    }
    _focusFromMyAccountElement(e) {
        const isHamburgerMenuEnabled = this.shadowRoot.querySelectorAll('.gn-main-nav button.gn-main-button[data-hamburger-menu="true"]').length ||
            this.shadowRoot.querySelectorAll('.gn-main-nav a.gn-main-link.stat-image-link[data-hamburger-menu="true"]')
                .length > 0
            ? 'true'
            : 'false';
        const getLogoFocusableElement = () => {
            const logoContent = this.shadowRoot.querySelector('.gn-logo-container');
            const { first } = getFirstAndLastFocusableElement(logoContent);
            return first;
        };
        if ((isSmallViewport() || isMediumViewport()) && isHamburgerMenuEnabled !== 'true') {
            if (this._myAccountNav.flyout !== 'open') {
                const elToFocus = getLogoFocusableElement();
                elToFocus.focus();
            }
        }
        else if (this.shadowRoot
            .querySelectorAll('.gn-main-nav button.gn-main-button[flyout="primary-1"]')[0]
            .classList.contains('hide-small') ||
            this.shadowRoot
                .querySelectorAll('.gn-main-nav button.gn-main-button[flyout="primary-1"]')[0]
                .classList.contains('hide-medium')) {
            const bodyEl = this.nextElementSibling;
            bodyEl.focus();
            e.preventDefault();
        }
    }
    _focusPreviousVisibleElement(e) {
        const isHamburgerMenuEnabled = this.shadowRoot.querySelectorAll('.gn-main-nav button.gn-main-button[data-hamburger-menu="true"]').length ||
            this.shadowRoot.querySelectorAll('.gn-main-nav a.gn-main-link.stat-image-link[data-hamburger-menu="true"]')
                .length > 0
            ? 'true'
            : 'false';
        const getMainNavFirstFocusableElement = () => {
            const mainNav = this.shadowRoot.querySelector('.gn-main-nav');
            const first = mainNav.firstElementChild;
            return first;
        };
        const getMiscNavFirstFocusableElement = () => {
            const miscNav = this.shadowRoot.querySelector('.gn-misc');
            const { first } = getFirstAndLastFocusableElement(miscNav);
            return first;
        };
        const getMyAccountFocusableElement = () => {
            const mainContent = document.querySelector('main');
            const { first } = getFirstAndLastFocusableElement(mainContent);
            return first;
        };
        const getHBMenuMainNavLastFocusableEl = () => {
            const mainNav = this.shadowRoot.querySelector('.gn-main-nav');
            const mainNavEls = mainNav.querySelectorAll('[class*="gn-main"]:not(.hide-large)');
            const last = mainNavEls[mainNavEls.length - 1];
            return last;
        };
        const currElement = this.shadowRoot.activeElement;
        if (isHamburgerMenuEnabled === 'true') {
            const logoEl = this.shadowRoot.querySelector('.gn-logo-container a');
            const skipToMainContentBtn = this.shadowRoot.querySelector('.gb-skip-to-main-content');
            const myaccEl = getMiscNavFirstFocusableElement();
            const lastLinkMainNavEl = getHBMenuMainNavLastFocusableEl();
            const hamburgerBtn = (this.shadowRoot.querySelector('.gn-main-nav button.gn-main-button[data-hamburger-menu="true"]'));
            const primaryLinkBtn1 = (this.shadowRoot.querySelector('.gn-main-nav button.gn-main-button[flyout="primary-1"]'));
            const primaryLinkBtn2 = (this.shadowRoot.querySelector('.gn-main-nav button.gn-main-button[flyout="primary-2"]'));
            let elementToFocus;
            if (isSmallViewport() || isMediumViewport()) {
                if (currElement === logoEl) {
                    elementToFocus = hamburgerBtn;
                }
                else if (currElement === myaccEl) {
                    elementToFocus = logoEl;
                }
                else if (currElement === hamburgerBtn) {
                    elementToFocus = skipToMainContentBtn;
                }
                else if (currElement === primaryLinkBtn1) {
                    elementToFocus = myaccEl;
                }
                else if (currElement === primaryLinkBtn2) {
                    elementToFocus = primaryLinkBtn1;
                }
            }
            else {
                if (currElement === logoEl) {
                    elementToFocus = lastLinkMainNavEl;
                }
                else if (currElement === myaccEl) {
                    elementToFocus = logoEl;
                }
                else if (currElement === hamburgerBtn) {
                    elementToFocus = skipToMainContentBtn;
                }
            }
            if (elementToFocus) {
                elementToFocus.focus();
                e.preventDefault();
            }
        }
        if (isSmallViewport() || isMediumViewport()) {
            if (isHamburgerMenuEnabled !== 'true') {
                if (currElement === getMainNavFirstFocusableElement()) {
                    const elToFocus = getMyAccountFocusableElement();
                    elToFocus.focus();
                }
                else if (currElement === getMiscNavFirstFocusableElement()) {
                    const elToFocus = getMainNavFirstFocusableElement();
                    elToFocus.focus();
                }
            }
        }
    }
    _focusFromMainContentElement() {
        const getMiscNavFirstFocusableElement = () => {
            const miscNav = this.shadowRoot.querySelector('.gn-misc');
            const { first } = getFirstAndLastFocusableElement(miscNav);
            return first;
        };
        if (isSmallViewport() || isMediumViewport()) {
            const elToFocus = getMiscNavFirstFocusableElement();
            elToFocus.focus();
        }
    }
    _handleTabEvent(e) {
        if (isTabOnly(e)) {
            this._focusNextVisibleNavElement(e);
        }
    }
    _handleMyAccountTabEvent(e) {
        if (isTabOnly(e)) {
            this._focusFromMyAccountElement(e);
        }
    }
    _handleShiftTabEvent(e) {
        if (isShiftTab(e)) {
            this._focusPreviousVisibleElement(e);
        }
    }
    _handleMainContentShiftTabEvent(e) {
        if (isShiftTab(e)) {
            this._focusFromMainContentElement();
        }
    }
    _initAuthState() {
        if (window.gbpe) {
            this._renderAuthState();
        }
        else {
            window.addEventListener(PortableExperiencesEvents.loaded, () => this._renderAuthState());
        }
    }
    _renderAuthState() {
        return __awaiter(this, void 0, void 0, function* () {
            yield window.gbpe.auth.loaded;
        });
    }
    _initFetchedContent(markup) {
        const rawContent = parseString(markup);
        const template = rawContent.querySelector('template');
        const content = template.content.cloneNode(true);
        Array.from(content.children).forEach(c => this.shadowRoot.appendChild(c));
        this._initExtrasListeners();
        this._initWindowResizeListener();
        this._dispatchRendered(INITIAL_RENDER);
        return this._initSkipToMainContent();
    }
    _initShadowDom() {
        this.attachShadow({ mode: 'open' });
        const stylesTag = document.createElement('style');
        stylesTag.textContent = this._styles;
        this.shadowRoot.appendChild(stylesTag);
        this._addPrintStyles();
    }
    _initExtrasListeners() {
        const extrasContainer = this.shadowRoot.querySelector('.gn-extras');
        document.addEventListener('keydown', e => {
            if (e.key === 'Tab') {
                extrasContainer.contains(document.activeElement);
                this._dispatchRendered(HEIGHT_RENDER);
            }
        });
    }
    _initSkipToMainContent() {
        const skipToMainContentBtn = this.shadowRoot.querySelector('.gb-skip-to-main-content');
        skipToMainContentBtn.classList.add('stat-button-link');
        skipToMainContentBtn.setAttribute('data-dtm', 'global nav');
        const mainContentHack = document.createElement('div');
        mainContentHack.tabIndex = -1;
        mainContentHack.className = 'gb-main-content';
        skipToMainContentBtn.addEventListener('click', () => mainContentHack.focus());
        return mainContentHack;
    }
    _initStaticContent() {
        const templateContents = this.querySelector('template');
        const contents = templateContents.content.cloneNode(true);
        this.shadowRoot.appendChild(contents);
        templateContents.remove();
        this._initExtrasListeners();
        this._initWindowResizeListener();
        this._dispatchRendered(INITIAL_RENDER);
        return this._initSkipToMainContent();
    }
    _initWindowResizeListener() {
        window.addEventListener('resize', debounce(this._handleResize.bind(this), 200));
    }
    _handleResize() {
        this._closeFlyoutIfNotMediumViewport();
        this._dispatchRendered(HEIGHT_RENDER);
    }
    _closeFlyoutIfNotMediumViewport() {
        if (!isMediumViewport() && this._isHorizontalWindowResize()) {
            this.flyoutService.closeOpenFlyout();
            if (window.gbpe.overlay.overlayShown)
                window.gbpe.overlay.toggleOverlay();
        }
    }
    _isHorizontalWindowResize() {
        let isHorizontalWindowResize = false;
        if (window.innerWidth !== this._windowInnerWidth) {
            this._windowInnerWidth = window.innerWidth;
            isHorizontalWindowResize = true;
        }
        return isHorizontalWindowResize;
    }
    _renderDynamic(variation) {
        return __awaiter(this, void 0, void 0, function* () {
            this._checkNavParent();
            const url = getNetStorageContentPath(variation, this._templateSuffix, this._baseUrlOverride);
            let html;
            try {
                const response = yield fetch(url);
                if (response.status === 404)
                    throw new Error();
                const rawText = yield response.text();
                const isJson = rawText.includes(`"status":`);
                html = isJson ? JSON.parse(rawText).html : rawText;
            }
            catch (_a) {
                throw new Error(`The variation ${variation} does not exist at ${url}.`);
            }
            this._initShadowDom();
            const mainContentDiv = this._initFetchedContent(html);
            this.parentNode.insertBefore(mainContentDiv, this.nextSibling);
            this._initAuthState();
        });
    }
    _renderStatic() {
        return __awaiter(this, void 0, void 0, function* () {
            this._checkNavParent();
            this._initShadowDom();
            const mainContentDiv = this._initStaticContent();
            this.parentNode.insertBefore(mainContentDiv, this.nextSibling);
            this._initAuthState();
        });
    }
    _checkNavParent() {
        const navParent = this.parentNode;
        if (navParent.nodeName.toLowerCase() !== 'body' && !STORYBOOK)
            throw new Error('gb-global-nav must be an immediate child of the body tag.');
    }
    _addPrintStyles() {
        const printerStylesTag = document.createElement('style');
        printerStylesTag.textContent = this._printStyles;
        document.head.appendChild(printerStylesTag);
    }
    _toggleAuthStateElements(elements, action) {
        elements.forEach(e => {
            e.el.style.display = action === 'show' ? e.initialDisplay : 'none';
        });
    }
    _toggleHide() {
        if (this.hide) {
            this.classList.add('gb-gn-hiding');
            this.addEventListener('animationend', () => {
                this.classList.add('gb-gn-hidden');
                this.classList.remove('gb-gn-hiding');
            }, {
                once: true,
            });
        }
        else {
            this.classList.remove('gb-gn-hidden');
            this.classList.add('gb-gn-showing');
            this.addEventListener('animationend', () => {
                this.classList.remove('gb-gn-showing');
            }, {
                once: true,
            });
        }
    }
    _toggleSticky() {
        if (this.sticky) {
            this.classList.add('gb-gn-sticky');
            this._dispatchRendered(NAV_STICKY);
        }
        else {
            this.classList.remove('gb-gn-sticky');
            this._dispatchRendered(NAV_UNSTICKY);
        }
    }
    get flyoutService() {
        return this._flyoutService;
    }
    _dispatchRendered(name) {
        const detail = {
            element: this,
            height: this.offsetHeight,
        };
        this.dispatchEvent(new CustomEvent(name, { detail, bubbles: true }));
    }
}
__decorate([
    prop()
], GlobalNav.prototype, "_baseUrlOverride", void 0);
__decorate([
    prop('boolean')
], GlobalNav.prototype, "disableScrollUpShow", void 0);
__decorate([
    prop('boolean', '_toggleHide')
], GlobalNav.prototype, "hide", void 0);
__decorate([
    prop('number')
], GlobalNav.prototype, "showScrollHeight", void 0);
__decorate([
    prop('boolean', '_toggleSticky')
], GlobalNav.prototype, "sticky", void 0);
__decorate([
    prop()
], GlobalNav.prototype, "variation", void 0);
__decorate([
    prop()
], GlobalNav.prototype, "enableLanguageSelector", void 0);
__decorate([
    prop()
], GlobalNav.prototype, "appContentSelector", void 0);
