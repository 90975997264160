import { gbpeCelestiqModalActivated } from '../../config/auth-content-config';
import { AuthEvents, AuthState } from '../../config/auth-enumerations';
export const HeadlessAuth = (_clientApplication, _sessionStorageKeyPrefix) => {
    function _clearManagedSessionStorageKeys() {
        if (_sessionStorageKeyPrefix) {
            const keys = Object.keys(sessionStorage).filter(k => k.startsWith(_sessionStorageKeyPrefix));
            keys.forEach(k => sessionStorage.removeItem(k));
        }
    }
    function _clearManagedLocalStorageItems() {
        localStorage.removeItem(gbpeCelestiqModalActivated);
    }
    const publicMembers = {
        loaded: new Promise((resolve, reject) => {
            window.addEventListener(AuthEvents.loaded, () => resolve(null));
        }),
        addAuthHeader(headers) {
            const reqHeaders = headers !== null && headers !== void 0 ? headers : new Headers();
            const bearer = `Bearer ${getIdToken()}`;
            reqHeaders.append('Authorization', bearer);
            return reqHeaders;
        },
        loginRedirect(url, extraParams, forceLogin) {
            _clientApplication.loginRedirect(url, extraParams, forceLogin);
        },
        logout(url) {
            _clearManagedSessionStorageKeys();
            _clearManagedLocalStorageItems();
            _clientApplication.logout(url);
        },
        registerRedirect(registerUrl, finalURL) {
            _clientApplication.registerRedirect(registerUrl, finalURL);
        },
        setInactiveTimeOut(timeout) {
            return _clientApplication.setInactiveTimeOut(timeout);
        },
    };
    function getCurrentAuthState() {
        return _clientApplication.authState;
    }
    function getIdToken() {
        if (getCurrentAuthState() === AuthState.authenticated) {
            return _clientApplication.idToken;
        }
        else {
            throw new Error('Id Token Error: Authentication Required');
        }
    }
    function getAccessToken() {
        if (getCurrentAuthState() === AuthState.authenticated) {
            return _clientApplication.accessToken;
        }
        else {
            throw new Error('Access Token Error: Authentication Required');
        }
    }
    function getIsMissingInfoRequired() {
        return _clientApplication.missingInfoRequired;
    }
    Object.defineProperties(publicMembers, {
        accessToken: { get: getAccessToken },
        currentAuthState: { get: getCurrentAuthState },
        idToken: { get: getIdToken },
        isMissingInfoRequired: { get: getIsMissingInfoRequired },
    });
    return publicMembers;
};
