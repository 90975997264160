var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { component } from '../core';
import { animateOpen, animateClose } from '../utils';
import { isShiftTab, isTabOnly } from '../utils';
import { getFirstAndLastFocusableElement } from '../utils/firstlast-focusable-elements';
import { isRTL } from '../../../../gbds-core/src/ts/utils/helper-utils';
export var TabNavEvents;
(function (TabNavEvents) {
    TabNavEvents["stackedTabClosed"] = "gb-stackedTabClosed";
    TabNavEvents["stackedTabOpened"] = "gb-stackedTabOpened";
})(TabNavEvents || (TabNavEvents = {}));
let TabNav = class TabNav extends HTMLElement {
    constructor() {
        super();
        this._tabContainerMap = new Map();
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this._tabButtons = Array.from(this.querySelectorAll('.content-button button'));
            this._contentContainers = Array.from(this.querySelectorAll('.content-container'));
            this._backButtons = Array.from(this.querySelectorAll('.content-container .back-button'));
            this._multipleDefaultCheck();
            this._tabButtons.forEach(btn => {
                const content = this._contentContainers.find(cnt => cnt.dataset.contentId === btn.dataset.tabId);
                const backButton = this._backButtons.find(bck => bck.parentElement.dataset.contentId === btn.dataset.tabId);
                this._tabContainerMap.set(btn.dataset.tabId, { tabButton: btn, content, backButton });
            });
            if (this.defaultTab) {
                this.defaultTab.parentElement.classList.toggle('active');
            }
            if (!this.isStackedView) {
                this.toggleTab(this.defaultTab);
            }
            else {
                this._tabContainerMap.get(this.defaultTab.dataset.tabId).content.classList.add('visible');
            }
            this._addTabListeners();
            if (isRTL()) {
                this.style.setProperty('--rotate-stacked-button-arrow', 'rotate(180deg)');
                this.style.setProperty('--rotate-back-button-arrow', 'rotate(0deg)');
            }
        });
    }
    _multipleDefaultCheck() {
        const defaultTabs = this._tabButtons.filter(tb => tb.parentElement.classList.contains('default'));
        if (defaultTabs.length > 1) {
            console.warn('gb-tab-nav: Multiple tabs set as default. Please only set one tab as the default.');
        }
    }
    _addTabListeners() {
        this._tabButtons.forEach(btn => {
            btn.addEventListener('click', e => {
                this.toggleTab(e.currentTarget);
                e.preventDefault();
            });
        });
    }
    _setFirstLastTabbableElements(contentContainer, backButton) {
        const { first, last } = getFirstAndLastFocusableElement(contentContainer);
        this._firstFocusableElement = first || backButton;
        this._lastFocusableElement = last || backButton;
    }
    toggleTab(tabButton) {
        if (tabButton) {
            const tabID = tabButton.dataset.tabId;
            const openTab = this.openTab;
            if (openTab)
                openTab.parentElement.classList.remove('active');
            tabButton.parentElement.classList.toggle('active');
            this.toggleContainer(tabID);
        }
    }
    toggleContainer(tabID) {
        const contentContainer = this._tabContainerMap.get(tabID).content;
        const backButton = this._tabContainerMap.get(tabID).backButton;
        const hamburgerMenu = this.closest('gb-flyout[data-hamburger-menu="true"]');
        if (this.openContainer) {
            this.openContainer.classList.remove('visible', 'open');
        }
        if (this.lastOpened) {
            this.lastOpened.classList.remove('last-opened');
        }
        if (this.isStackedView) {
            let yPosition = this.getBoundingClientRect().y < 0 ? 0 : this.getBoundingClientRect().y;
            if (this.isInFlyout) {
                yPosition = this.closest('gb-flyout').getBoundingClientRect().y;
            }
            this._setFirstLastTabbableElements(contentContainer, backButton);
            backButton.addEventListener('keydown', e => {
                if (isShiftTab(e)) {
                    this.closeTab(tabID);
                    e.preventDefault();
                }
            });
            if (hamburgerMenu) {
                const hbMenuCloseBtn = this.closest('gb-flyout[data-hamburger-menu="true"]').querySelector('.gb-flyout-close');
                const hbMenuFlyout = hamburgerMenu;
                hbMenuFlyout.style.visibility = 'hidden';
                hbMenuCloseBtn.classList.add('gb-in-stacked-hb-close');
                this._lastFocusableElement = hbMenuCloseBtn;
            }
            this._lastFocusableElement.addEventListener('keydown', e => {
                if (isTabOnly(e) && e.currentTarget !== backButton) {
                    backButton.focus();
                    e.preventDefault();
                }
            });
            contentContainer.style.top = yPosition + 'px';
            contentContainer.style.height = 'calc(100% - ' + yPosition + 'px)';
            animateOpen(contentContainer);
            this.dispatchEvent(new CustomEvent(TabNavEvents.stackedTabOpened, { detail: { openContainer: contentContainer } }));
        }
        contentContainer.classList.add('visible');
        backButton.focus();
        backButton.addEventListener('click', () => this.closeTab(tabID));
    }
    closeTab(tabID) {
        const contentContainer = this._tabContainerMap.get(tabID).content;
        const hamburgerMenu = this.closest('gb-flyout[data-hamburger-menu="true"]');
        if (this.lastOpened !== undefined) {
            this.lastOpened.classList.remove('last-opened');
        }
        contentContainer.classList.remove('visible');
        animateClose(contentContainer);
        this.dispatchEvent(new CustomEvent(TabNavEvents.stackedTabClosed, { detail: { closedContainer: contentContainer } }));
        contentContainer.classList.add('last-opened');
        this._tabContainerMap.get(tabID).tabButton.focus();
        if (hamburgerMenu) {
            const hbMenuCloseBtn = this.closest('gb-flyout[data-hamburger-menu="true"]').querySelector('.gb-flyout-close');
            hbMenuCloseBtn.classList.remove('gb-in-stacked-hb-close');
            const hbMenuFlyout = hamburgerMenu;
            hbMenuFlyout.style.visibility = '';
        }
    }
    get isStackedView() {
        return this._tabButtons.find(btn => window.getComputedStyle(btn).display !== 'inline-block');
    }
    get isInFlyout() {
        return this.closest('gb-flyout') != null;
    }
    get openTab() {
        return this._tabButtons.find(btn => btn.parentElement.classList.contains('active'));
    }
    get defaultTab() {
        return this._tabButtons.find(btn => btn.parentElement.classList.contains('default'));
    }
    get openContainer() {
        return this._contentContainers.find(cnt => cnt.classList.contains('visible' || 'open'));
    }
    get lastOpened() {
        return this._contentContainers.find(cnt => cnt.classList.contains('last-opened'));
    }
};
TabNav = __decorate([
    component('gb-tab-nav')
], TabNav);
export { TabNav };
