var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const regionalDefaultAttributePrefix = 'data-others';
const disclosureAttribute = `${regionalDefaultAttributePrefix}-disclosure`;
const textAttribute = `${regionalDefaultAttributePrefix}-msrp`;
const regionalDisclosureSelector = `[${disclosureAttribute}]`;
const regionalTextSelector = `[${textAttribute}]`;
const combinedRegionalContentSelector = `${regionalDisclosureSelector}, ${regionalTextSelector}`;
function iterate(array, callback) {
    for (let i = 0; i < array.length; i++) {
        const item = array[i];
        callback(item);
    }
}
function getElementsWithAttributeStrings(container, selector, ...attributeStrings) {
    return Array.from(container.querySelectorAll(selector)).filter(e => attributeStrings.some(attributeString => e.hasAttribute(attributeString)));
}
function replaceInnerContent(container, content) {
    const domContent = new DOMParser().parseFromString(content, 'text/html');
    Array.from(container.children).forEach(n => n.remove());
    domContent.body.childNodes.forEach(n => container.appendChild(n));
}
function replaceTextContent(container, text) {
    container.textContent = text;
}
function replaceWithRegionalInnerContent(array, region) {
    iterate(array, e => {
        const regionDisclosureAttributeMatch = findAttributeMatch(e, a => a.name.toLowerCase() === `data-${region.toLowerCase()}-disclosure`);
        const regionTextAttributeMatch = findAttributeMatch(e, a => a.name.toLowerCase() === `data-${region.toLowerCase()}-msrp`);
        replaceRegionalMatchedAttributeContent(e, regionDisclosureAttributeMatch, regionTextAttributeMatch, `${regionalDefaultAttributePrefix}-disclosure`, replaceInnerContent);
        replaceRegionalMatchedAttributeContent(e, regionTextAttributeMatch, regionDisclosureAttributeMatch, `${regionalDefaultAttributePrefix}-msrp`, replaceTextContent);
    });
}
function findAttributeMatch(element, callback) {
    return Array.from(element.attributes).find(a => callback(a));
}
function replaceRegionalMatchedAttributeContent(element, attribute, attributeYouDontWant, defaultAttribute, callback) {
    if (attribute && !attributeYouDontWant) {
        callback(element, attribute.value);
    }
    else {
        const defaultMatch = findAttributeMatch(element, a => a.name.toLowerCase() === defaultAttribute.toLowerCase());
        if (defaultMatch)
            callback(element, defaultMatch.value);
    }
}
export function initRegionalContent() {
    return __awaiter(this, void 0, void 0, function* () {
        const nav = document.querySelector('gb-global-nav');
        yield nav.flyoutService.allFlyoutsLoaded;
        window.gbpe.regionService.currentRegion$.subscribe(region => {
            const navRegionalDisclosure = getElementsWithAttributeStrings(nav.shadowRoot, combinedRegionalContentSelector, disclosureAttribute, textAttribute);
            replaceWithRegionalInnerContent(navRegionalDisclosure, region.name);
        });
    });
}
