export const imageLoader = (root = document.body) => {
    const lazyImages = root.querySelectorAll('.gb-responsive-image, gb-static-image img');
    const hasIntersectionObserver = 'IntersectionObserver' in window;
    if (hasIntersectionObserver) {
        const imageObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const img = entry.target;
                    const sources = img.querySelectorAll('source');
                    if (sources.length !== 0) {
                        const rimg = img.querySelector('img');
                        if (!rimg.hasAttribute('data-hq')) {
                            const src = rimg.dataset.src;
                            rimg.classList.add('gb-lazy-waiting');
                            _addLoadingEventListeners(rimg);
                            sources.forEach(src => {
                                const srcset = src.dataset.srcset;
                                if (srcset) {
                                    const parentWidth = src.parentElement.offsetWidth;
                                    const newWidth = parentWidth > 1900 || !parentWidth || parentWidth === 0 ? 1900 : parentWidth;
                                    const retinaWidth = newWidth * 1.6;
                                    const newSrcset = srcset
                                        .replace(/(\?imwidth=)[0-9]+(?:&impolicy=lqip)?(\s1x)/i, '$1' + newWidth + '$2')
                                        .replace(/(\?imwidth=)[0-9]+(?:&impolicy=lqip)?(\s2x)/i, '$1' + Math.round(retinaWidth) + '$2');
                                    src.setAttribute('srcset', newSrcset);
                                }
                            });
                            if (src)
                                rimg.setAttribute('src', src);
                        }
                    }
                    else if (!img.hasAttribute('data-hq')) {
                        const src = img.dataset.src;
                        img.classList.add('gb-lazy-waiting');
                        _addLoadingEventListeners(img);
                        if (src)
                            img.setAttribute('src', src);
                    }
                }
            });
        });
        lazyImages.forEach(image => {
            imageObserver.observe(image);
        });
    }
    else {
        _setImageMarkup(lazyImages);
    }
};
const _addLoadingEventListeners = target => {
    target.addEventListener('load', e => _lazyImageLoad(e), { once: true });
    target.addEventListener('error', e => _lazyImageError(e), { once: true });
};
const _lazyImageLoad = e => {
    e.currentTarget.classList.remove('gb-lazy-waiting');
    e.currentTarget.setAttribute('data-hq', '');
    e.currentTarget.classList.add('gb-fade-in');
};
const _lazyImageError = e => {
    const placeholder = '/etc.clientlibs/clientlibs/solar/resources/placeholder_clear100x1.png';
    if (e.currentTarget.parentElement.querySelectorAll('source').length !== 0) {
        const sources = e.currentTarget.parentElement.querySelectorAll('source');
        sources.forEach(src => {
            src.setAttribute('srcset', '');
        });
    }
    e.currentTarget.setAttribute('src', placeholder);
    e.currentTarget.classList.remove('gb-lazy-waiting');
    e.currentTarget.classList.add('gb-lazy-error');
};
const _setImageMarkup = lazyImages => {
    lazyImages.forEach(img => {
        try {
            const sources = img.querySelectorAll('source');
            const src = img.tagName.toLowerCase() === 'img' ? img.dataset.src : img.querySelector('img').dataset.src;
            sources.forEach(src => {
                const srcset = src.dataset.srcset;
                if (srcset) {
                    const parentWidth = src.parentElement.offsetWidth;
                    const newWidth = parentWidth > 1900 || !parentWidth || parentWidth === 0 ? 1900 : parentWidth;
                    const retinaWidth = newWidth * 1.6;
                    const newSrcset = srcset
                        .replace(/(\?imwidth=)[0-9]+(?:&impolicy=lqip)?(\s1x)/i, '$1' + newWidth + '$2')
                        .replace(/(\?imwidth=)[0-9]+(?:&impolicy=lqip)?(\s2x)/i, '$1' + Math.round(retinaWidth) + '$2');
                    src.setAttribute('srcset', newSrcset);
                }
            });
            if (src && sources.length !== 0) {
                img.querySelector('img').setAttribute('src', src);
            }
            else {
                img.setAttribute('src', src);
            }
        }
        catch (error) {
            console.log('Unable to update attributes from dataset: ', error);
        }
    });
};
