var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { UserInfoItems } from '../../../models/user-info';
import { PortableAPI } from './portable-api';
import { MissingInfoApi } from './missing-info-api';
import { initUserData } from '../../../config/user-info-config';
import { NotificationAPI } from './notification-api';
import { KoreaProfileRefresh } from './korea-profile-refresh';
import { KoreaCheckJson } from './korea-check-json';
import { CartAPI } from './cart-api';
let apiInstance;
export class UserInformation {
    constructor(urlConfig) {
        this._userData = initUserData(urlConfig);
    }
    _getPortableApi(name, dependency) {
        return __awaiter(this, void 0, void 0, function* () {
            const apiData = this._userData[name];
            apiData.dependentInstance = dependency;
            if (apiInstance === undefined || apiInstance._item !== apiData.item) {
                apiInstance = new PortableAPI(apiData);
            }
            if (apiInstance._item === apiData.item) {
                return apiInstance.getInterface();
            }
        });
    }
    _getNotificationAPI(name) {
        return __awaiter(this, void 0, void 0, function* () {
            const apiData = this._userData[name];
            if (apiInstance === undefined || apiInstance._item !== apiData.item) {
                apiInstance = new NotificationAPI(apiData);
            }
            if (apiInstance._item === apiData.item) {
                return apiInstance.getInterface();
            }
        });
    }
    _getCartAPI(name) {
        return __awaiter(this, void 0, void 0, function* () {
            const apiData = this._userData[name];
            if (apiInstance === undefined || apiInstance._item !== apiData.item) {
                apiInstance = new CartAPI(apiData);
            }
            if (apiInstance._item === apiData.item) {
                return apiInstance.getInterface();
            }
        });
    }
    _getKoreaProfileRefresh(name) {
        return __awaiter(this, void 0, void 0, function* () {
            const apiData = this._userData[name];
            if (apiInstance === undefined || apiInstance._item !== apiData.item) {
                apiInstance = new KoreaProfileRefresh(apiData);
            }
            if (apiInstance._item === apiData.item) {
                return apiInstance.getInterface();
            }
        });
    }
    _getKoreaCheckJson(name) {
        return __awaiter(this, void 0, void 0, function* () {
            const apiData = this._userData[name];
            if (apiInstance === undefined || apiInstance._item !== apiData.item) {
                apiInstance = new KoreaCheckJson(apiData);
            }
            if (apiInstance._item === apiData.item) {
                return apiInstance.getInterface();
            }
        });
    }
    get profile() {
        return this._getPortableApi(UserInfoItems.profile);
    }
    get rewards() {
        return this._getPortableApi(UserInfoItems.rewards);
    }
    get missingInfo() {
        return this._getPortableApi(UserInfoItems.missingInfo, new MissingInfoApi());
    }
    get vehicles() {
        return this._getPortableApi(UserInfoItems.vehicles);
    }
    get notifications() {
        return this._getNotificationAPI(UserInfoItems.notifications);
    }
    get cart() {
        return this._getCartAPI(UserInfoItems.cart);
    }
    get koreaProfileRefresh() {
        return this._getKoreaProfileRefresh(UserInfoItems.koreaProfileRefresh);
    }
    get koreaCheckJson() {
        return this._getKoreaCheckJson(UserInfoItems.koreaCheckJson);
    }
}
