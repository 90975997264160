export class GBModalService {
    static setupModalOpenListener() {
        document.body.addEventListener('click', e => {
            let target = e.target;
            let targetModalId = target.getAttribute('data-gb-modal-id');
            if (!targetModalId) {
                target = target.closest('[data-gb-modal-id]');
                if (target) {
                    targetModalId = target.getAttribute('data-gb-modal-id');
                }
            }
            if (targetModalId) {
                e.preventDefault();
                GBModalService.openModal({
                    modalId: targetModalId,
                    title: target.getAttribute('data-gb-modal-title') || '',
                    titleCustomColor: target.getAttribute('data-gb-modal-title-custom-color') || '',
                    contentPath: target.getAttribute('data-gb-modal-content-path'),
                    isFullscreen: target.hasAttribute('data-gb-modal-is-legacy-gallery')
                        ? true
                        : target.hasAttribute('data-gb-modal-is-fullscreen'),
                    isLeftAligned: target.hasAttribute('data-gb-modal-is-left-aligned'),
                    anchor: target.getAttribute('data-gb-modal-anchor') || null,
                    isLegacyGallery: target.hasAttribute('data-gb-modal-is-legacy-gallery'),
                    scrollToSlide: target.hasAttribute('data-gb-modal-is-legacy-gallery')
                        ? parseInt(target.getAttribute('data-slide-scrollto'), 10)
                        : 0,
                });
            }
        });
    }
    static openModal(modalData) {
        const preexistingModalEl = document.querySelector(`gb-modal#${modalData.modalId}`);
        if (preexistingModalEl) {
            if (!isNaN(modalData.scrollToSlide)) {
                preexistingModalEl.setAttribute('data-scroll-to-slide', modalData.scrollToSlide.toString());
            }
            else {
                preexistingModalEl.removeAttribute('data-scroll-to-slide');
            }
            preexistingModalEl.openModal(modalData);
        }
        else {
            const newModalEl = document.createElement('gb-modal');
            newModalEl.setAttribute('id', modalData.modalId);
            if (!isNaN(modalData.scrollToSlide)) {
                newModalEl.setAttribute('data-scroll-to-slide', modalData.scrollToSlide.toString());
            }
            else {
                newModalEl.removeAttribute('data-scroll-to-slide');
            }
            document.body.appendChild(newModalEl);
            newModalEl.openModal(modalData);
        }
    }
    static closeModal(modalId) {
        const modalEl = document.querySelector(`gb-modal#${modalId}`);
        if (modalEl) {
            modalEl.hideModal();
        }
        else {
            window.console.log(`ERROR: Cannot close modal with id ${modalId} becaused it was not found`);
        }
    }
    static setupExternalLinkListener() {
        document.body.addEventListener('click', (e) => {
            let el = e.target;
            while (el) {
                if (el.nodeName === 'A' && el.target === '_blank') {
                    const url = el.href;
                    if (!url || typeof url !== 'string') {
                        window.console.log('Error: Invalid or empty URL found on link element.');
                        break;
                    }
                    const isExternal = /^https?|^\/\//.test(url);
                    const popupEnabled = !!document.querySelector('gb-modal#external-popup-content-reveal');
                    const bypassPopup = el.getAttribute('data-displaypopup') === 'false' || url.endsWith('#');
                    if (isExternal && popupEnabled && !bypassPopup) {
                        e.preventDefault();
                        GBModalService.onExternalSiteModal(url);
                    }
                    break;
                }
                el = el.parentNode;
            }
        }, true);
    }
    static onExternalSiteModal(url) {
        const modalId = 'external-popup-content-reveal';
        const externalSiteModalEl = document.querySelector(`gb-modal#${modalId}`);
        if (!externalSiteModalEl) {
            console.error('External site modal element not found.');
            return;
        }
        GBModalService.openModal({
            modalId: modalId,
        });
        const continueBtnEl = externalSiteModalEl.querySelector('.gb-popup-cont-button');
        const cancelBtnEl = externalSiteModalEl.querySelector('.gb-popup-cancel-button');
        if (!continueBtnEl || !cancelBtnEl) {
            console.error('Continue or cancel button not found in external site modal.');
            return;
        }
        if (!continueBtnEl.getAttribute('listener-attached')) {
            const handleContinueClick = (e) => {
                e.preventDefault();
                const urlWithHash = url.endsWith('/') ? `${url.slice(0, -1)}#` : `${url}#`;
                window.open(urlWithHash, '_blank');
                GBModalService.closeModal(modalId);
                continueBtnEl.removeEventListener('click', handleContinueClick);
                continueBtnEl.removeAttribute('listener-attached');
            };
            continueBtnEl.addEventListener('click', handleContinueClick);
            continueBtnEl.setAttribute('listener-attached', 'true');
        }
        if (!cancelBtnEl.getAttribute('listener-attached')) {
            const handleCloseClick = (e) => {
                e.preventDefault();
                GBModalService.closeModal(modalId);
                cancelBtnEl.removeEventListener('click', handleCloseClick);
                cancelBtnEl.removeAttribute('listener-attached');
            };
            cancelBtnEl.addEventListener('click', handleCloseClick);
            cancelBtnEl.setAttribute('listener-attached', 'true');
        }
    }
}
