var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { component } from '@gbds/core/src/ts/core';
import { imageLoader } from '../../../utils/image-lazy-loader';
let StaticImage = class StaticImage extends HTMLElement {
    constructor() {
        super();
    }
    onInit() {
        if (document.readyState !== 'loading') {
            if (!this.hasAttribute('data-hq'))
                imageLoader(this);
        }
        else {
            window.addEventListener('DOMContentLoaded', () => {
                if (!this.hasAttribute('data-hq'))
                    imageLoader.bind(this);
            });
        }
    }
    disconnectedCallback() {
    }
};
StaticImage = __decorate([
    component('gb-static-image')
], StaticImage);
export { StaticImage };
