export const MYACCOUNT_PROFILE_URL = '/api/_gbpe/v3/profiles';
export const MYACCOUNT_REWARDS_URL = '/api/_gbpe/v3/rewards';
export const MYACCOUNT_VEHICLE_URL = '/ownercenter/api/garagesummary';
export const MYACCOUNT_MISSINGINFO_URL = '/ownercenter/api/checkMissingInfo';
export const GM_SERVER_TIME_PATH = '/api/_gbpe/currenttime';
export const API_CONNECT_TOKEN_VALIDATION_PATH = '/api/_gbpe/v3/isValidToken';
export const API_CONNECT_TOKEN_VALIDATION_PATH_EU = '/ch-en/api/_gbpe/v3/isValidToken';
export const API_LOGOUT_CLEAR_COOKIE_PATH = '/api/_gbpe/clearCookie';
export const BRANDS_MY_ACCOUNT_VERIFICATION_URL = '/myaccount/verify';
export const DEFAULT_MY_ACCOUNT_VERIFICATION_URL = '/verify';
export const CREATE_ACCOUNT_URL = '/myaccount/authorize/create-account/email-input';
export const BRANDS_DETOUR_URL = '/detour?region=';
export const LEGACY_KOREA_SERVER_ChkLoginJSON_PATH = '/bypass/korea/quantum/member/ChkLoginJson.gm';
export const LEGACY_KOREA_APP_PROFILE_REFRESH_PATH = '/bypass/korea/quantum/member/login-refresh.gm';
export const LEGACY_KOREA_AUTH_ERROR_PATH = '/skl-auth-error';
