export const defaultConfigData = [
    { name: 'auth', attributeName: 'show-auth-users', callback: '_validateAuthLinks' },
    { name: 'unauth', attributeName: 'show-unauth-users', callback: '_validateUnauthLinks' },
    { name: 'rewards', attributeName: 'show-auth-rewards-users', callback: '_validateRewardsLinks' },
    { name: 'nonrewards', attributeName: 'show-auth-nonrewards-users', callback: '_validateNonRewardsLinks' },
];
export const brandContentConfigData = [
    {
        brand: 'cadillac',
        config: [{ name: 'celestiq', attributeName: 'show-celestiq-users', callback: '_validateCelestiqLinks' }],
    },
];
export const gbpeCelestiqModalActivated = 'gbpe.celestiq.modalActivated';
