var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AuthStorageKeys } from '../../../config/auth-enumerations';
import { sanitizeUrl } from '@gbds/core/src/ts/utils/helper-utils';
import { parseTokenForEntitlements } from '../../../utils/auth/verify-token';
import { BRANDS_DETOUR_URL } from '../../../config/paths';
export class MissingInfoApi {
    constructor() {
        this.MISSING_INFO_STORAGE_KEY = 'GBPE_API_CACHE_MISSINGINFO';
        this.processMissingInfo = () => this.processMissingInfoAndRedirect();
        this.completed = () => this.completeProcess();
        document.addEventListener('gb-myaccountReset', e => checkMissingInfo(e));
    }
    getSessionStorage(storageKey) {
        try {
            if (sessionStorage.getItem('GBPE_API_CACHE_MISSINGINFO') === 'COMPLETED') {
                return;
            }
            this._cache =
                JSON.parse(sessionStorage.getItem(storageKey))['actionRequired'] ||
                    JSON.parse(sessionStorage.getItem(storageKey));
            if (this._cache.length === 0) {
                sessionStorage.setItem(this.MISSING_INFO_STORAGE_KEY, 'COMPLETED');
                return;
            }
            this._cache.sort((a, b) => (!b.priority || a.priority < b.priority ? -1 : 1));
            sessionStorage.setItem(this.MISSING_INFO_STORAGE_KEY, JSON.stringify(this._cache));
            return this._cache;
        }
        catch (e) {
            console.log('Exception in fetching from Missing Info session storage!!');
        }
    }
    get isProcessPending() {
        var _a;
        this._cache = this.getSessionStorage(this.MISSING_INFO_STORAGE_KEY);
        if (!this._cache) {
            return false;
        }
        this._currentProcess = this._cache[0];
        if ((_a = this._currentProcess) === null || _a === void 0 ? void 0 : _a.url) {
            return true;
        }
        if (this._currentProcess.url === '' || this._currentProcess.url === undefined) {
            this._currentProcess.completed = 'true';
            return false;
        }
        return false;
    }
    processMissingInfoAndRedirect() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (this.isProcessPending) {
                yield window.gbpe.auth.loaded;
                if (window.gbpe.auth.currentAuthState === 1 && ((_a = location === null || location === void 0 ? void 0 : location.href) === null || _a === void 0 ? void 0 : _a.includes(BRANDS_DETOUR_URL)) === false) {
                    this.redirectToTCPS(this._currentProcess.url);
                }
            }
            else {
                console.log('No pending MissingInfo!!');
            }
        });
    }
    redirectToTCPS(url) {
        this._currentProcess.completed = 'IN_PROGRESS';
        this._cache[0] = this._currentProcess;
        sessionStorage.setItem(this.MISSING_INFO_STORAGE_KEY, JSON.stringify(this._cache));
        window.location.href = sanitizeUrl(url);
    }
    completeProcess() {
        console.log('Missing info Process completed() called..');
        if (sessionStorage.getItem('GBPE_API_CACHE_MISSINGINFO') === 'COMPLETED') {
            console.log('Missing Info Process is COMPLETED.No pending process!!');
        }
        else {
            try {
                this._cache = JSON.parse(sessionStorage.getItem('GBPE_API_CACHE_MISSINGINFO'));
                this._cache = this._cache.filter(a => a.completed !== 'IN_PROGRESS');
                sessionStorage.setItem(this.MISSING_INFO_STORAGE_KEY, JSON.stringify(this._cache));
                console.log('process completed..');
                const stateParam = sessionStorage.getItem(AuthStorageKeys.state);
                window.location.href = stateParam ? sanitizeUrl(stateParam) : window.location.origin;
            }
            catch (e) {
                console.log('Cannot complete processs!!');
            }
        }
    }
}
export const checkMissingInfo = (event) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    if ((event === null || event === void 0 ? void 0 : event.type) === 'gb-myaccountReset' && ((_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.itemName) !== 'missingInfo') {
        return;
    }
    yield window.gbpe.auth.loaded;
    const entitlement = window.gbpe.auth.currentAuthState === 1 ? parseTokenForEntitlements(window.gbpe.auth.idToken) : '';
    const CELESTIQ_ENTITLEMENT = ['CELESTIQVIP', 'JDCONCIERGE'];
    const isCelestiq = entitlement ? CELESTIQ_ENTITLEMENT.filter(item => entitlement.includes(item)).length > 0 : false;
    const entitledUser = isCelestiq === true;
    if (entitledUser === true) {
        window.console.warn('Missing Info calls are blocked for Entitled users');
    }
    try {
        if (sessionStorage.getItem('GBPE_API_CACHE_MISSINGINFO') === 'COMPLETED') {
            console.log('Missing Info Api Process Completed!!');
        }
        else {
            const cache = JSON.parse(sessionStorage.getItem('GBPE_API_CACHE_MISSINGINFO'));
            const isProcessPending = cache === 'COMPLETED' ? false : true;
            const inProgress = (cache === null || cache === void 0 ? void 0 : cache.filter(a => a.completed === 'IN_PROGRESS' && window.location.href.includes(a.url)).length)
                ? true
                : false;
            const authState = window.gbpe.auth.currentAuthState;
            const recheckMissingInfo = (cache === null || cache === void 0 ? void 0 : cache.length) === 0 ? true : false;
            if (recheckMissingInfo) {
                const missingInfo = yield window.gbpe.user.missingInfo;
                missingInfo.reset();
                missingInfo.processMissingInfo();
            }
            if (authState === 1 &&
                window.gbpe.auth.isMissingInfoRequired &&
                isProcessPending &&
                !inProgress &&
                entitledUser === false) {
                const missingInfo = yield window.gbpe.user.missingInfo;
                missingInfo.processMissingInfo();
            }
        }
    }
    catch (e) {
        console.log('Missing Info Api call failed!!');
    }
});
