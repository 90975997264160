import { MapEvents } from '../models/google-map';
export class MapsApiLoader {
    static get isLoaded() {
        return typeof google === 'object' && typeof google.maps === 'object';
    }
    static getScriptSource(loaderOpts) {
        let source = loaderOpts.url ? loaderOpts.url : 'https://maps.googleapis.com/maps/api/js';
        const language = loaderOpts.language ? `&language=${loaderOpts.language}` : '';
        const region = loaderOpts.region ? `&region=${loaderOpts.region}` : '';
        const channel = loaderOpts.channel ? `&channel=${loaderOpts.channel}` : '';
        source += `?v=weekly&client=${loaderOpts.clientId}` + channel;
        return `${source}${language}${region}&libraries=places`;
    }
    static load(opts) {
        if (MapsApiLoader.isLoaded)
            return new Promise(resolve => resolve());
        if (MapsApiLoader.isLoading)
            return new Promise((resolve, reject) => {
                window.addEventListener(MapEvents.loaded, () => resolve(), { once: true });
                window.addEventListener(MapEvents.error, (e) => reject(e.detail), { once: true });
            });
        MapsApiLoader.isLoading = true;
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = MapsApiLoader.getScriptSource(opts);
            script.onerror = () => {
                MapsApiLoader.isLoading = false;
                const error = new Error('Google Maps API failed to load.');
                reject(error);
                window.dispatchEvent(new CustomEvent(MapEvents.error, { detail: error }));
            };
            script.onload = () => {
                MapsApiLoader.isLoading = false;
                resolve();
                window.dispatchEvent(new CustomEvent(MapEvents.loaded));
            };
            document.body.appendChild(script);
        });
    }
}
MapsApiLoader.isLoading = false;
