export var PriceShortCodes;
(function (PriceShortCodes) {
    PriceShortCodes["startingPrice"] = "{{starting_price}}";
    PriceShortCodes["asShownPrice"] = "{{as_shown_price}}";
})(PriceShortCodes || (PriceShortCodes = {}));
export var DisclosureShortCodes;
(function (DisclosureShortCodes) {
    DisclosureShortCodes["startingPriceDisclosure"] = "{{starting_price_disclosure}}";
    DisclosureShortCodes["asShownPriceDisclosure"] = "{{as_shown_price_disclosure}}";
})(DisclosureShortCodes || (DisclosureShortCodes = {}));
