import { getBaseHeadlessAuth, PortableExperiences } from '../../../../utils/portable-experiences';
import { UserInformation } from '../../user-info/user-info';
import { Overlay } from '../../../overlay';
import { UltiumOverlay } from '../../../ultium-overlay';
import { checkMissingInfo } from '../../user-info/missing-info-api';
import { getAuthConfig } from '../../../../config/auth-config';
import { initializeGbpe } from '../../../../utils/initialize-gbpe';
import { AuthContentService } from '../../../../utils/auth-content-service';
import { EntitlementService } from '../../../../utils/auth/entitlement-service';
export function initDefaultGbpe(config, missingInfo = true) {
    const authConfig = getAuthConfig(config);
    const userInfo = new UserInformation(config.urls);
    const baseGbpeObject = PortableExperiences(authConfig, userInfo, getBaseHeadlessAuth(authConfig, config.urls, authConfig.sessionStorageKeyPrefix), new Overlay(document.body), config.urls, new EntitlementService(), new AuthContentService());
    initializeGbpe(authConfig, userInfo, config, baseGbpeObject);
    if (missingInfo === true) {
        checkMissingInfo().then();
    }
}
export function initDefaultGbpeUltiumOverlay(config, missingInfo = true) {
    const authConfig = getAuthConfig(config);
    const userInfo = new UserInformation(config.urls);
    const baseGbpeObject = PortableExperiences(authConfig, userInfo, getBaseHeadlessAuth(authConfig, config.urls, authConfig.sessionStorageKeyPrefix), new UltiumOverlay(document.body), config.urls, new EntitlementService(), new AuthContentService());
    initializeGbpe(authConfig, userInfo, config, baseGbpeObject);
    if (missingInfo === true) {
        checkMissingInfo().then();
    }
}
