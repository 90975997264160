export function reportErrorToDynatrace(error) {
    try {
        if (window.hasOwnProperty('dtrum') && window['dtrum'] !== undefined) {
            const dynatraceObject = window['dtrum'];
            dynatraceObject === null || dynatraceObject === void 0 ? void 0 : dynatraceObject.reportError(error);
        }
        else {
            console.log('Dynatrace not defined');
        }
    }
    catch (e) {
        console.error('Error checking for dynatrace: ', e);
    }
}
