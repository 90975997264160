/* eslint-disable */
window.er = window.er || (events => {
	const eventsListening = events && events.length > 0 ? [...events] : []
	const filterStrings = []

	if (eventsListening.length > 0) {
		addRetargetingListenerToArrayItems(eventsListening)
	}

	return function () {
		if (arguments && arguments.length > 0) {
			filterStrings.push(...arguments)
		}

		return function () {
			if (arguments && arguments.length > 0) {
				const argumentArray = arguments && arguments.length > 0 ? [...arguments] : []
				const eventsToAdd = argumentArray.filter(eventName => !eventsListening.includes(eventName))

				if (eventsToAdd.length > 0) {
					eventsListening.push(...eventsToAdd)
					addRetargetingListenerToArrayItems(eventsToAdd)
				}
			} else {
				console.warn(`[Event Retargeting] Invalid invocation of curried 'cr' function. Second function call must contain arguments, e.g. 'cr()("click")'.`)
			}
		}
	}

	function addRetargetingListenerToArrayItems(eventsToListen) {
		for (let i = 0; i < eventsToListen.length; i++) {
			const eventName = eventsToListen[i]
			addRetargetingListener(eventName)
		}	
	}

	function addRetargetingListener(eventName) {
		window.addEventListener(eventName, e => {
			const inFragment = e.composedPath().some(n => n instanceof DocumentFragment)
			// If er() hasn't been called we still want to retarget events coming from shadow roots.
			const matchedElement = filterStrings.length === 0 || e.composedPath().some(n => {
				const htmlElement = n instanceof HTMLElement
				let classNameMatches = false
				let nodeNameMatches = false

				if (!htmlElement) return false

				for (let i = 0; i < filterStrings.length; i++) {
					const stringToMatch = filterStrings[i].toLowerCase()
					const classMatch = Array.from(n.classList.values()).some(c => c.toLowerCase().startsWith(stringToMatch))
					const nodeMatch = n.nodeName.toLowerCase().startsWith(stringToMatch)

					if (classMatch) classNameMatches = true
					if (nodeMatch) nodeNameMatches = true
					if (classNameMatches || nodeNameMatches) break	
				}

				return htmlElement && (classNameMatches || nodeNameMatches)
			})

			if (inFragment && matchedElement) {
				const newTarget = e.composedPath()[0]

				Object.defineProperty(e, 'target', {
					get: () => newTarget,
					configurable: true,
					enumerable: true,
				})
			}
		}, {
			capture: true,
			passive: true,
		})
	}

	/*
		Note: touch and mouse events also trigger pointer events. Pointer events do NOT trigger touch or mouse events.
		So if we only add a 'pointerup' listener, a 'click' listener will NOT be retargeted.
		
		A pointer event is fired when a touch or mouse event is fired. So this script will be ran multiple times anyway.
	*/
})(['touchend', 'mouseup', 'drag', 'click'])
