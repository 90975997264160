var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { component } from '@gbds/core/src/ts/core';
let RegionalPrice = class RegionalPrice extends HTMLElement {
    constructor() {
        super();
    }
    onInit() {
        var _a, _b;
        const initialRegion = localStorage.getItem('gmwp.region_select_cookie')
            ? JSON.parse(localStorage.getItem('gmwp.region_select_cookie')).regionCode
            : 'default';
        if ((_b = (_a = window.gbpe) === null || _a === void 0 ? void 0 : _a.regionService) === null || _b === void 0 ? void 0 : _b.currentRegion$) {
            this._currentRegionSubscription = window.gbpe.regionService.currentRegion$.subscribe(region => this._replaceRegionSpecificTextContent(region.code));
        }
        else {
            this._replaceRegionSpecificTextContent(initialRegion);
        }
        if (!this._currentRegionSubscription) {
            this._addEventListeners();
        }
    }
    disconnectedCallback() {
        if (this._currentRegionSubscription)
            this._currentRegionSubscription.unsubscribe();
        this._removeEventListeners();
    }
    _addEventListeners() {
        document.addEventListener('gbds-selectable-list-event', () => {
            this._currentRegionSubscription = window.gbpe.regionService.currentRegion$.subscribe(region => this._replaceRegionSpecificTextContent(region.code));
        });
    }
    _replaceRegionSpecificTextContent(region) {
        const regionPriceAttribue = region.toLowerCase() + '-price';
        this._hasRegionPriceAttribute(regionPriceAttribue)
            ? (this.textContent = this.getAttribute(regionPriceAttribue))
            : (this.textContent = this.getAttribute('default-price'));
        this.classList.remove('hide');
    }
    _hasRegionPriceAttribute(regionPriceAttr) {
        return this.hasAttribute(regionPriceAttr);
    }
    _removeEventListeners() {
        document.removeEventListener('gbds-selectable-list-event', () => {
            this._currentRegionSubscription = window.gbpe.regionService.currentRegion$.subscribe(region => this._replaceRegionSpecificTextContent(region.code));
        });
    }
};
RegionalPrice = __decorate([
    component('gb-regional-price')
], RegionalPrice);
export { RegionalPrice };
