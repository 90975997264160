var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { GbdsElement, prop } from '@gbds/core/src/ts/core';
import { INITIAL_RENDER } from '../events';
export { INITIAL_RENDER } from '../events';
import { getNetStorageContentPath } from '../utils/netstorage-paths';
import { parseString } from '../utils/dom-string-parser';
import { isWebViewApp } from '@gbds/core/src/ts/utils/helper-utils';
export class GbpeFooter extends GbdsElement {
    constructor(footerBrandStyles, _defaultVariation) {
        super();
        this._defaultVariation = _defaultVariation;
        this.templateSuffix = '-global_footer.html';
        this._styles = footerBrandStyles;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (!isWebViewApp()) {
                const childTemplates = this.getElementsByTagName('template');
                if (this.childElementCount !== 0 && childTemplates.length > 0) {
                    this.renderStatic();
                    this._addContentRole();
                }
                else {
                    if (this.getAttribute('lazyload-footer')) {
                        this.initFooterObserver();
                    }
                    else {
                        yield this.renderDynamic((_a = this.variation) !== null && _a !== void 0 ? _a : this._defaultVariation);
                        this._addContentRole();
                    }
                }
            }
        });
    }
    _addContentRole() {
        if (this.getAttribute('role') === null) {
            this.setAttribute('role', 'contentinfo');
        }
    }
    renderStatic() {
        this.initShadowDom();
        this.initStaticContent();
    }
    initShadowDom() {
        this.attachShadow({ mode: 'open' });
        const stylesTag = document.createElement('style');
        stylesTag.textContent = this._styles;
        this.shadowRoot.appendChild(stylesTag);
    }
    renderDynamic(variation) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = getNetStorageContentPath(variation, this.templateSuffix, this._baseUrlOverride);
            try {
                const response = yield fetch(url);
                const rawText = yield response.text();
                const isJson = rawText.includes(`"status":`);
                const html = isJson ? JSON.parse(rawText).html : rawText;
                this.initShadowDom();
                this.initFetchedContent(html);
            }
            catch (_a) {
                throw new Error(`The variation ${variation} does not exist at ${url}.`);
            }
        });
    }
    initFetchedContent(markup) {
        const rawContent = parseString(markup);
        const template = rawContent.querySelector('template');
        const content = template.content.cloneNode(true);
        Array.from(content.children).forEach(c => this.shadowRoot.appendChild(c));
        this.dispatchRendered();
    }
    initStaticContent() {
        const templateContents = this.querySelector('template');
        const contents = templateContents.content.cloneNode(true);
        this.shadowRoot.appendChild(contents);
        templateContents.remove();
        this.dispatchRendered();
    }
    dispatchRendered() {
        const detail = {
            height: this.offsetHeight,
        };
        this.dispatchEvent(new CustomEvent(INITIAL_RENDER, { detail, bubbles: true }));
    }
    initFooterObserver() {
        const footer = document.querySelectorAll('gb-global-footer');
        const customMargin = '50%';
        const observerOptions = { rootMargin: customMargin };
        const intersectionCallback = entries => {
            entries.forEach((entry) => __awaiter(this, void 0, void 0, function* () {
                var _a;
                if (entry.isIntersecting) {
                    observer.unobserve(entry.target);
                    yield this.renderDynamic((_a = this.variation) !== null && _a !== void 0 ? _a : this._defaultVariation);
                    this._addContentRole();
                }
            }));
        };
        const observer = new IntersectionObserver(intersectionCallback, observerOptions);
        observer.observe(footer[0]);
    }
}
__decorate([
    prop()
], GbpeFooter.prototype, "variation", void 0);
__decorate([
    prop()
], GbpeFooter.prototype, "_baseUrlOverride", void 0);
