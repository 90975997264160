export function getBrandFromUrl(url = location.origin) {
    return ((url.includes('chevrolet') && 'chevrolet') ||
        (url.includes('gmc') && 'gmc') ||
        (url.includes('cadillac') && 'cadillac') ||
        (url.includes('buick') && 'buick') ||
        (url.includes('onstar') && 'onstar') ||
        (url.includes('gmparts') && 'gmparts') ||
        'gm');
}
export function isBrandSite() {
    const nonBrandSiteList = ['myaccount', 'ownercenter'];
    return nonBrandSiteList.includes(window.location.pathname.split('/')[1]) ? false : true;
}
export function isNonCelestiqPage() {
    const celestiqPage = window.location.pathname.match('/electric/celestiq');
    return (celestiqPage === null || celestiqPage === void 0 ? void 0 : celestiqPage.length) ? false : true;
}
