var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isAbsoluteUrl } from '@gbds/core/src/ts/utils';
import { API_CONNECT_TOKEN_VALIDATION_PATH, API_CONNECT_TOKEN_VALIDATION_PATH_EU, BRANDS_MY_ACCOUNT_VERIFICATION_URL, DEFAULT_MY_ACCOUNT_VERIFICATION_URL, GM_SERVER_TIME_PATH, MYACCOUNT_PROFILE_URL, } from '../../config/paths';
import { AuthStorageKeys } from '../../config/auth-enumerations';
import { backUpServerTimeUrl, backUpVerifyUrl } from '../../config/verify-header-test';
import { verificationDomains } from '../../config/verification-domains-test';
function getServerOffset() {
    return __awaiter(this, arguments, void 0, function* (serverURLArray = [backUpServerTimeUrl, GM_SERVER_TIME_PATH]) {
        const serverURL = serverURLArray.pop();
        if (!serverURL) {
            return 0;
        }
        const serverResponse = yield fetch(serverURL);
        const serverString = yield serverResponse.text();
        const serverMilliseconds = Number(serverString) * 1000;
        if (serverResponse.status !== 200 || isNaN(serverMilliseconds)) {
            window.console.log('getting server time failed for: ', serverURL);
            return getServerOffset(serverURLArray);
        }
        else {
            const serverTime = new Date(serverMilliseconds);
            const now = Date.now();
            const serverOffset = now - serverTime.getTime();
            sessionStorage.setItem(AuthStorageKeys.serverOffset, String(serverOffset));
            return serverOffset;
        }
    });
}
export function isTokenExpired(timeToCheck) {
    return __awaiter(this, void 0, void 0, function* () {
        const offset = yield getOffset();
        const now = Date.now();
        const difference = now - timeToCheck.getTime() - offset;
        return difference > 0;
    });
}
export function getTimeToExpiration(timeToCheck) {
    return __awaiter(this, void 0, void 0, function* () {
        const offset = yield getOffset();
        const now = Date.now();
        return timeToCheck.getTime() - now + offset;
    });
}
function getOffset() {
    return __awaiter(this, void 0, void 0, function* () {
        const offsetNumber = Number(sessionStorage.getItem(AuthStorageKeys.serverOffset));
        if (isNaN(offsetNumber)) {
            return yield getServerOffset();
        }
        else {
            return offsetNumber;
        }
    });
}
export function validateStateParam(state) {
    if (isAbsoluteUrl(state)) {
        const url = new URL(state);
        return url.origin === window.location.origin;
    }
    return true;
}
export function parseTokenForExpiration(token) {
    try {
        const payload = atob(token.split('.')[1]);
        const payloadJSON = JSON.parse(payload);
        const expiration = payloadJSON.exp;
        const expirationMilliseconds = Number(expiration);
        return new Date(expirationMilliseconds * 1000);
    }
    catch (e) {
        console.log('Error parsing token for expiration claim', e);
        return null;
    }
}
export function parseTokenForEmail(token) {
    try {
        const payload = atob(token.split('.')[1]);
        const payloadJSON = JSON.parse(payload);
        return payloadJSON.email;
    }
    catch (e) {
        console.log('Error parsing Token for email claim', e);
        return null;
    }
}
export function parseTokenForName(token) {
    try {
        const payload = atob(token.split('.')[1]);
        const payloadJSON = JSON.parse(payload);
        return payloadJSON.name;
    }
    catch (e) {
        console.log('Error parsing Token for name claim', e);
        return null;
    }
}
export function getVerificationStatusFromToken(token) {
    try {
        const payload = atob(token.split('.')[1]);
        const payloadJSON = JSON.parse(payload);
        return payloadJSON.verification_status;
    }
    catch (e) {
        console.log('Error parsing Token for name claim', e);
        return null;
    }
}
export function redirectToVerificationPage(redirectPath) {
    var _a, _b, _c, _d, _e, _f;
    let verificationURL;
    const country = (_c = (_b = (_a = window === null || window === void 0 ? void 0 : window.gbpe) === null || _a === void 0 ? void 0 : _a.config) === null || _b === void 0 ? void 0 : _b.locale) === null || _c === void 0 ? void 0 : _c.split('-')[1];
    const language = (_f = (_e = (_d = window === null || window === void 0 ? void 0 : window.gbpe) === null || _d === void 0 ? void 0 : _d.config) === null || _e === void 0 ? void 0 : _e.locale) === null || _f === void 0 ? void 0 : _f.split('-')[0];
    const brandURLs = [];
    for (const value in verificationDomains) {
        brandURLs.push(verificationDomains[value]);
    }
    if (redirectPath && redirectPath !== '') {
        verificationURL =
            isAbsoluteUrl(redirectPath) && redirectPath.includes(window.location.origin)
                ? new URL(redirectPath)
                : new URL(redirectPath, window.location.origin);
    }
    else {
        if (brandURLs && brandURLs.includes(window.location.origin)) {
            if ((country === null || country === void 0 ? void 0 : country.toUpperCase()) === 'CA') {
                verificationURL = new URL(`/${language}${BRANDS_MY_ACCOUNT_VERIFICATION_URL}`, window.location.origin);
            }
            else {
                verificationURL = new URL(BRANDS_MY_ACCOUNT_VERIFICATION_URL, window.location.origin);
            }
        }
        else {
            verificationURL = new URL(DEFAULT_MY_ACCOUNT_VERIFICATION_URL, window.location.origin);
        }
    }
    if (!window.location.href.toLowerCase().includes(verificationURL.href.toLowerCase())) {
        console.log('here is where we redirect to if email is not verified', verificationURL.href);
        window.location.href = verificationURL.href;
    }
}
export function validateToken(token) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a;
        const headers = new Headers();
        const bearer = token;
        headers.append('Authorization', bearer);
        headers.append('locale', 'en-US');
        let finalTokenValidationPath = API_CONNECT_TOKEN_VALIDATION_PATH;
        const windowURL = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.origin;
        if (windowURL.endsWith('.ca') || windowURL.includes('canada') || windowURL.includes('-ca.')) {
            finalTokenValidationPath = `/en${API_CONNECT_TOKEN_VALIDATION_PATH}`;
            headers.set('locale', 'en-CA');
        }
        if (windowURL.includes('europe')) {
            finalTokenValidationPath = `${API_CONNECT_TOKEN_VALIDATION_PATH_EU}`;
            headers.set('locale', 'en-CH');
        }
        const options = {
            method: 'GET',
            headers: headers,
        };
        const responseStatus = yield fetch(finalTokenValidationPath, options)
            .then(response => response.status)
            .catch(error => {
            console.error('Error has occurred in token validation: ', error);
        });
        if (responseStatus === 404) {
            console.log('API token validation url does not exist in current domain');
            const defaultResponseStatus = yield fetch(backUpVerifyUrl, options)
                .then(response => response.status)
                .catch(error => {
                console.error('Error occurred in backup token validation url:', error);
            });
            return defaultResponseStatus !== undefined ? defaultResponseStatus === 200 : false;
        }
        else {
            if (responseStatus !== undefined && responseStatus !== 200) {
                console.log('Token validation failed');
            }
            return responseStatus !== undefined ? responseStatus === 200 : false;
        }
    });
}
export function getRegionRedirect(token, locale, region, profileURL) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a, _b, _c;
        const headers = new Headers();
        headers.append('Authorization', token);
        headers.append('locale', locale);
        headers.append('region', region);
        const options = {
            method: 'GET',
            headers: headers,
        };
        const regionCheckEndpoint = profileURL ? profileURL : MYACCOUNT_PROFILE_URL;
        try {
            const regionResponse = yield fetch(regionCheckEndpoint, options);
            if (regionResponse.status !== 200) {
                throw new Error('Region Response has failed');
            }
            const regionResponseJSON = yield regionResponse.json();
            const country = regionResponseJSON === null || regionResponseJSON === void 0 ? void 0 : regionResponseJSON.country;
            const language = locale.split('-')[0];
            const actionRequired = country !== undefined && country !== region;
            const countryGBPE = (_c = (_b = (_a = window === null || window === void 0 ? void 0 : window.gbpe) === null || _a === void 0 ? void 0 : _a.config) === null || _b === void 0 ? void 0 : _b.locale) === null || _c === void 0 ? void 0 : _c.split('-')[1];
            console.log(`Logged in User's Country: ${country}\nSite's Detected Country: ${countryGBPE}`);
            let brandsDetourURL = '';
            const detourCountries = ['US', 'KR', 'CA', 'CH', 'SE', 'DE', 'FR', 'GB', 'NL', 'NO', 'LO', 'IT'];
            if (detourCountries.includes(countryGBPE)) {
                if (countryGBPE === 'US') {
                    brandsDetourURL = '/detour';
                }
                else if (countryGBPE === 'CA') {
                    brandsDetourURL = '/en/detour';
                }
                else {
                    brandsDetourURL = `/${countryGBPE}-en/detour`;
                }
            }
            else {
                console.log(`Site's Country Not Configured for Detour\nDetected Site's Country: ${countryGBPE}`);
            }
            return actionRequired === true ? `${brandsDetourURL}` : '';
        }
        catch (error) {
            console.error('Error getting Region Response', error);
            return '';
        }
    });
}
export function parseTokenForEntitlements(token) {
    try {
        const payload = atob(token.split('.')[1]);
        const payloadJSON = JSON.parse(payload);
        return payloadJSON === null || payloadJSON === void 0 ? void 0 : payloadJSON.Entitlements;
    }
    catch (e) {
        console.log('Error parsing Token for Entitlements', e);
        return null;
    }
}
