var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { component, prop } from '@gbds/core/src/ts/core';
import { getFirstAndLastFocusableElement } from '@gbds/core/src/ts/utils/firstlast-focusable-elements';
let Disclosure = class Disclosure extends HTMLElement {
    constructor() {
        super();
        this.handleClick = e => {
            e.preventDefault();
            e.stopPropagation();
            try {
                this.processDisclosureData().then(disclosure => {
                    var _a;
                    if (disclosure !== null) {
                        ((_a = disclosure.disclosureTitle) === null || _a === void 0 ? void 0 : _a.length) ? this.btn.setAttribute('title', disclosure.disclosureTitle) : '';
                        this.openDisclosure(disclosure.disclosureContent, disclosure.buttonLabel);
                        this._addEscapeListener();
                    }
                });
            }
            catch (e) {
                console.log(`Error handling disclosure click`, e);
            }
        };
        this._disclosureTemplate = (content, buttonLabel) => {
            const template = document.createDocumentFragment();
            this.wrapper = document.createElement('div');
            this.wrapper.classList.add('gb-disclosure-wrapper');
            this.disContainer = document.createElement('div');
            this.disContainer.classList.add('gb-disclosure-container');
            this.disContainer.setAttribute('role', 'dialog');
            this.disContainer.setAttribute('aria-modal', 'true');
            this.disContent = document.createElement('div');
            this.disContent.classList.add('gb-disclosure-content');
            this.disContent.innerHTML = content;
            this.buttonContainer = document.createElement('div');
            this.buttonContainer.classList.add('gb-disclosure-button-container');
            this.closeButton = document.createElement('button');
            this.closeButton.classList.add('disclosure-close-button');
            this.closeButton.addEventListener('click', this._closeOpenDisclosure.bind(this));
            this.closeButton.textContent = buttonLabel;
            this.buttonContainer.appendChild(this.closeButton);
            this.disContainer.appendChild(this.disContent);
            this.disContainer.appendChild(this.buttonContainer);
            this.wrapper.appendChild(this.disContainer);
            template.appendChild(this.wrapper);
            return template;
        };
    }
    onInit() {
        const disclosureButton = this.querySelector('button');
        if (!disclosureButton) {
            this.innerHTML = `<button>${this.innerHTML}</button>`;
            this.btn = this.children[0];
        }
        else {
            this.btn = disclosureButton;
        }
        this.selfScriptEl = document.currentScript;
        this.btn.setAttribute('aria-haspopup', 'dialog');
        this.btn.addEventListener('click', e => this.handleClick(e));
    }
    disconnectedCallback() {
        this.btn.removeEventListener('click', e => this.handleClick(e));
    }
    _addEscapeListener() {
        document.addEventListener('keyup', e => this._escapeListenerHandler(e));
    }
    _escapeListenerHandler(e) {
        if (e.key === 'Escape' && this.disclosureOpen) {
            this._closeOpenDisclosure(e);
            e.preventDefault();
            e.stopImmediatePropagation();
        }
    }
    processDisclosureData() {
        return __awaiter(this, void 0, void 0, function* () {
            const disclosureDataContent = document.querySelector('.q-disclosure-data-content');
            if (this.selfScriptEl) {
                this.disclosureCloseLabel = this.selfScriptEl.getAttribute('data-close-label');
            }
            else {
                this.disclosureCloseLabel = 'Close';
            }
            let closeButtonLabel = this.disclosureCloseLabel;
            if (!closeButtonLabel || closeButtonLabel == '') {
                closeButtonLabel = 'Close';
            }
            try {
                if (!disclosureDataContent) {
                    return yield this.fetchDisclosureData(closeButtonLabel);
                }
                else {
                    const disclosureContent = disclosureDataContent.querySelector(`span[data-id="${this.dataDisclosureId}"]`);
                    if (!disclosureContent) {
                        return yield this.fetchDisclosureData(closeButtonLabel);
                    }
                    else {
                        const headlineData = disclosureContent.getAttribute('data-headline');
                        const titleData = disclosureContent.getAttribute('data-title');
                        const dataContent = disclosureContent.innerHTML;
                        return {
                            disclosureContent: dataContent,
                            buttonLabel: closeButtonLabel,
                            disclosureTitle: titleData,
                            headline: headlineData,
                        };
                    }
                }
            }
            catch (e) {
                console.log(`error attempting to fetch content for disclosure id ${this.dataDisclosureId}`, e);
                return null;
            }
        });
    }
    fetchDisclosureData(closeButtonLabel) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield fetch(this.dataDisclosureId + `.model.json`);
            const data = yield response.json();
            const title = data[':items']['disclaimerLibrary']['bbc-title'];
            const content = data[':items']['disclaimerLibrary']['bbc-content'];
            const headline = data[':items']['disclaimerLibrary']['bbc-headline'];
            return {
                disclosureContent: content,
                buttonLabel: closeButtonLabel,
                disclosureTitle: title,
                headline: headline,
            };
        });
    }
    _render(template) {
        if (template) {
            if (this.getRootNode() instanceof ShadowRoot) {
                const shadowRoot = this.getRootNode();
                shadowRoot.appendChild(template);
            }
            else {
                document.body.appendChild(template);
            }
        }
        else {
            this.wrapper.remove();
        }
    }
    openDisclosure(content, buttonLabel) {
        const disclosureWrapper = document.querySelector('.gb-disclosure-wrapper');
        if (!disclosureWrapper) {
            this._render(this._disclosureTemplate(content, buttonLabel));
            this.sourceDisclosureButton = this.children[0];
            this._handleDisclosureFocusableElements();
            const closeBtn = this.closeButton;
            closeBtn.focus();
            this.showDisclosure();
        }
        else {
            disclosureWrapper.remove();
            this.openDisclosure(content, buttonLabel);
        }
        return true;
    }
    _closeOpenDisclosure(e) {
        e.stopPropagation();
        this.closeOpenDisclosure();
        this.hideDisclosure();
    }
    closeOpenDisclosure() {
        if (this.disclosureOpen) {
            this._render();
            this.sourceDisclosureButton.focus();
            this.sourceDisclosureButton = undefined;
        }
    }
    _handleDisclosureFocusableElements() {
        const { first, last } = getFirstAndLastFocusableElement(this.disContent);
        if (first) {
            first.focus();
            first.addEventListener('keydown', (e) => {
                if (e.key === 'Tab' && e.shiftKey) {
                    this.closeButton.focus();
                    e.preventDefault();
                }
            });
        }
        else {
            this.closeButton.focus();
        }
        this.closeButton.addEventListener('keydown', (e) => {
            if (e.key === 'Tab' && !e.shiftKey) {
                first === null || first === void 0 ? void 0 : first.focus();
                e.preventDefault();
            }
            else if (e.key === 'Tab' && e.shiftKey) {
                last ? last.focus() : first === null || first === void 0 ? void 0 : first.focus;
                e.preventDefault();
            }
        });
    }
    showDisclosure() {
        if (!this.overlayEl) {
            this.overlayEl = document.createElement('gb-disclosure-overlay');
            if (this.getRootNode() instanceof ShadowRoot) {
                const shadowRoot = this.getRootNode();
                shadowRoot.appendChild(this.overlayEl);
            }
            else {
                document.body.appendChild(this.overlayEl);
            }
        }
        document.body.classList.add('gb-disclosure-open');
        this.closeButton.focus();
        document.addEventListener('keydown', this.handleKeyDown.bind(this));
        document.body.addEventListener('click', this.handleBodyClick.bind(this));
    }
    hideDisclosure() {
        if (this.overlayEl) {
            this.overlayEl.remove();
            this.overlayEl = undefined;
        }
        document.body.classList.remove('gb-disclosure-open');
        document.removeEventListener('keydown', this.handleKeyDown.bind(this));
        document.body.removeEventListener('click', this.handleBodyClick.bind(this));
    }
    handleKeyDown(e) {
        const key = e.which || e.keyCode;
        if (key === 27) {
            this._closeOpenDisclosure(e);
        }
    }
    handleBodyClick(e) {
        const target = e.target;
        if (target.tagName === 'GB-DISCLOSURE-OVERLAY') {
            this._closeOpenDisclosure(e);
        }
    }
    get disclosureOpen() {
        return this.wrapper.isConnected && this.wrapper.childElementCount > 0;
    }
};
__decorate([
    prop()
], Disclosure.prototype, "dataDisclosureId", void 0);
__decorate([
    prop()
], Disclosure.prototype, "disclosureContent", void 0);
__decorate([
    prop()
], Disclosure.prototype, "buttonLabel", void 0);
__decorate([
    prop()
], Disclosure.prototype, "disclosureTitle", void 0);
__decorate([
    prop()
], Disclosure.prototype, "headline", void 0);
Disclosure = __decorate([
    component('gb-disclosure')
], Disclosure);
export { Disclosure };
