export var verificationDomains;
(function (verificationDomains) {
    verificationDomains["buickProd2Akamai"] = "https://www-buick-com.prd2.wpx.gm.com";
    verificationDomains["buickProd2"] = "https://www-buick-com.wpsegment12.prd2.wpx.gm.com";
    verificationDomains["buickRcSitAkamai"] = "https://www-buick-com.rcsit.wpi.gm.com";
    verificationDomains["buickRcSit"] = "https://www-buick-com.wpsegment12.rcsit.wpi.gm.com";
    verificationDomains["buickCanadaProd2Akamai"] = "https://www-buick-ca.prd2.wpx.gm.com";
    verificationDomains["buickCanadaProd2"] = "https://www-buick-ca.wpsegment12.prd2.wpx.gm.com";
    verificationDomains["buickCanadaRcSitAkamai"] = "https://www-buick-ca.rcsit.wpi.gm.com";
    verificationDomains["buickCanadaRcSit"] = "https://www-buick-ca.wpsegment12.rcsit.wpi.gm.com";
    verificationDomains["cadillacProd2Akamai"] = "https://www-cadillac-com.prd2.wpx.gm.com";
    verificationDomains["cadillacProd2"] = "https://www-cadillac-com.wpsegment11.prd2.wpx.gm.com";
    verificationDomains["cadillacRcSitAkamai"] = "https://www-cadillac-com.rcsit.wpi.gm.com";
    verificationDomains["cadillacRcSit"] = "https://www-cadillac-com.wpsegment11.rcsit.wpi.gm.com";
    verificationDomains["cadillacCanadaProd2Akamai"] = "https://www-cadillaccanada-ca.prd2.wpx.gm.com";
    verificationDomains["cadillacCanadaProd2"] = "https://www-cadillaccanada-ca.wpsegment11.prd2.wpx.gm.com";
    verificationDomains["cadillacCanadaRcSitAkamai"] = "https://www-cadillaccanada-ca.rcsit.wpi.gm.com";
    verificationDomains["cadillacCanadaRcSit"] = "https://www-cadillaccanada-ca.wpsegment11.rcsit.wpi.gm.com";
    verificationDomains["chevyProd2Akamai"] = "https://www-chevrolet-com.prd2.wpx.gm.com";
    verificationDomains["chevyProd2"] = "https://www-chevrolet-com.wpsegment9.prd2.wpx.gm.com";
    verificationDomains["chevyRcSitAkamai"] = "https://www-chevrolet-com.rcsit.wpi.gm.com";
    verificationDomains["chevyRcSit"] = "https://www-chevrolet-com.wpsegment9.rcsit.wpi.gm.com";
    verificationDomains["chevyCanadaProd2Akamai"] = "https://www-chevrolet-ca.prd2.wpx.gm.com";
    verificationDomains["chevyCanadaProd2"] = "https://www-chevrolet-ca.wpsegment9.prd2.wpx.gm.com";
    verificationDomains["chevyCanadaRcSitAkamai"] = "https://www-chevrolet-ca.rcsit.wpi.gm.com";
    verificationDomains["chevyCanadaRcSit"] = "https://www-chevrolet-ca.wpsegment9.rcsit.wpi.gm.com";
    verificationDomains["crossBrandsProd2Akamai"] = "https://experience-gm-com.prd2.wpx.gm.com";
    verificationDomains["crossBrandsProd2"] = "https://experience-gm-com.wpsegment8.prd2.wpx.gm.com";
    verificationDomains["crossBrandsRcSitAkamai"] = "https://experience-gm-com.rcsit.wpi.gm.com";
    verificationDomains["crossBrandsRcSit"] = "https://experience-gm-com.wpsegment8.rcsit.wpi.gm.com";
    verificationDomains["crossBrandsCanadaProd2Akamai"] = "https://experience-gm-ca.prd2.wpx.gm.com";
    verificationDomains["crossBrandsCanadaProd2"] = "https://experience-gm-ca.wpsegment8.prd2.wpx.gm.com";
    verificationDomains["crossBrandsCanadaRcSitAkamai"] = "https://www-experience-gm-ca.rcsit.wpi.gm.com";
    verificationDomains["crossBrandsCanadaRcSit"] = "https://experience-gm-ca.wpsegment8.rcsit.wpi.gm.com";
    verificationDomains["gmcProd2Akamai"] = "https://www-gmc-com.prd2.wpx.gm.com";
    verificationDomains["gmcProd2"] = "https://www-gmc-com.wpsegment10.prd2.wpx.gm.com";
    verificationDomains["gmcRcSitAkamai"] = "https://www-gmc-com.rcsit.wpi.gm.com";
    verificationDomains["gmcRcSit"] = "https://www-gmc-com.wpsegment10.rcsit.wpi.gm.com";
    verificationDomains["gmcCanadaProd2Akamai"] = "https://www-gmccanada-ca.prd2.wpx.gm.com";
    verificationDomains["gmcCanadaProd2"] = "https://www-gmccanada-ca.wpsegment10.prd2.wpx.gm.com";
    verificationDomains["gmcCanadaRcSitAkamai"] = "https://www-gmccanada-ca.rcsit.wpi.gm.com";
    verificationDomains["gmcCanadaRcSit"] = "https://www-gmccanada-ca.wpsegment10.rcsit.wpi.gm.com";
})(verificationDomains || (verificationDomains = {}));
