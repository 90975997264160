export var windowSizeQueries;
(function (windowSizeQueries) {
    windowSizeQueries["small"] = "screen";
    windowSizeQueries["medium"] = "screen and (min-width: 800px)";
    windowSizeQueries["large"] = "screen and (min-width: 1200px)";
    windowSizeQueries["xlarge"] = "screen and (min-width: 9999999px)";
    windowSizeQueries["xxlarge"] = "screen and (min-width: 9999999px)";
})(windowSizeQueries || (windowSizeQueries = {}));
export function getWindowSize() {
    if (window.matchMedia(windowSizeQueries.xxlarge).matches) {
        return 'xxlarge';
    }
    else if (window.matchMedia(windowSizeQueries.xlarge).matches) {
        return 'xlarge';
    }
    else if (window.matchMedia(windowSizeQueries.large).matches) {
        return 'large';
    }
    else if (window.matchMedia(windowSizeQueries.medium).matches) {
        return 'medium';
    }
    return 'small';
}
export function isVerticallyInViewport(el) {
    const win = {
        h: window.innerHeight,
        w: window.innerWidth,
    };
    if (el) {
        const box = el.getBoundingClientRect();
        const topEdgeInRange = box.top >= 0 && box.top <= win.h;
        const bottomEdgeInRange = box.bottom >= 0 && box.bottom <= win.h;
        return !!(topEdgeInRange || bottomEdgeInRange || (box.height >= win.h && box.top < 0 && box.bottom > 0));
    }
    else {
        return false;
    }
}
