import { Overlay } from './overlay';
import styles from '@scss/overlay/overlay.scss';
import customStyles from '@scss/overlay/overlay-ultium.scss';
export class UltiumOverlay extends Overlay {
    _initializeOverlayElement(prepend, root) {
        this._overlay = document.createElement('div');
        this._overlay.classList.add('gb-overlay');
        const style = document.createElement('style');
        style.textContent = styles.toString();
        const customStyle = document.createElement('style');
        customStyle.textContent = customStyles.toString();
        this.setZHeight();
        this._insertStyles(prepend, root, style);
        this._insertStyles(prepend, root, customStyle);
        this._addOverlayClickListener(this._overlay);
    }
}
