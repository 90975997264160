import { PortableExperiences } from './portable-experiences';
export function initializeGbpe(authConfig, userInfo, config, portableExperiencesObject) {
    var _a, _b, _c, _d, _e, _f;
    if (window.gbpe) {
        console.warn('gbpe already defined, updating gbpe services');
        window.gbpe.auth = (_a = portableExperiencesObject.auth) !== null && _a !== void 0 ? _a : window.gbpe.auth;
        window.gbpe.languageService = (_b = portableExperiencesObject.languageService) !== null && _b !== void 0 ? _b : window.gbpe.languageService;
        window.gbpe.overlay = (_c = portableExperiencesObject.overlay) !== null && _c !== void 0 ? _c : window.gbpe.overlay;
        window.gbpe.regionService = (_d = portableExperiencesObject.regionService) !== null && _d !== void 0 ? _d : window.gbpe.regionService;
        window.gbpe.config = authConfig !== null && authConfig !== void 0 ? authConfig : window.gbpe.config;
        window.gbpe.user = userInfo !== null && userInfo !== void 0 ? userInfo : window.gbpe.user;
        window.gbpe.entitlementService = (_e = portableExperiencesObject.entitlementService) !== null && _e !== void 0 ? _e : window.gbpe.entitlementService;
        window.gbpe.authContentService = (_f = portableExperiencesObject.authContentService) !== null && _f !== void 0 ? _f : window.gbpe.authContentService;
    }
    else {
        window.gbpe = PortableExperiences(authConfig, userInfo, portableExperiencesObject === null || portableExperiencesObject === void 0 ? void 0 : portableExperiencesObject.auth, portableExperiencesObject === null || portableExperiencesObject === void 0 ? void 0 : portableExperiencesObject.overlay, config === null || config === void 0 ? void 0 : config.urls, portableExperiencesObject === null || portableExperiencesObject === void 0 ? void 0 : portableExperiencesObject.entitlementService, portableExperiencesObject === null || portableExperiencesObject === void 0 ? void 0 : portableExperiencesObject.authContentService);
        window.gbpe.finalize();
    }
}
