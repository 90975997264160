import { prop } from './prop';
import { AttributeParser } from '../utils/attribute-parser';
export function component(tag) {
    return function (Ctor) {
        prop('boolean')(Ctor.prototype, 'framework');
        const { onInit } = Ctor.prototype;
        if (onInit) {
            Ctor.prototype.connectedCallback = function () {
                if (!this.framework) {
                    const readyState = document.readyState;
                    if (readyState === 'interactive' || readyState === 'complete') {
                        this.onInit();
                    }
                    else {
                        window.addEventListener('DOMContentLoaded', this.onInit.bind(this), { once: true });
                    }
                }
            };
        }
        const isDefined = customElements.get(tag);
        if (!isDefined)
            customElements.define(tag, Ctor);
    };
}
export class GbdsElement extends HTMLElement {
    constructor() {
        super();
    }
    static get observedAttributes() {
        return this.observedProps.keys();
    }
    attributeChangedCallback(name, oldVal, newVal) {
        const observedProps = this.constructor.observedProps;
        const { changedFn, type } = observedProps.get(name);
        const parsedOldVal = AttributeParser.fromAttribute(oldVal, type);
        const parsedNewVal = AttributeParser.fromAttribute(newVal, type);
        this[changedFn](parsedOldVal, parsedNewVal);
    }
}
GbdsElement.observedProps = new Map();
