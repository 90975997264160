export class AuthLinkService {
    swapLinks() {
        let swappedLinks = 0;
        console.log(`Begin Swap Link Process...\n`);
        const elements = document.querySelectorAll('[data-auth-href], :host([data-auth-href])');
        elements.forEach((element) => {
            console.log(`Non-Shadow Element Detected to Swap: ${element}\n`);
            const authHref = element.getAttribute('data-auth-href');
            if (authHref) {
                element.setAttribute('href', authHref);
                swappedLinks++;
            }
        });
        const shadowRoot = document.querySelector('gb-global-nav').shadowRoot;
        if (shadowRoot) {
            const shadowElements = shadowRoot.querySelectorAll('[data-auth-href]');
            shadowElements.forEach((shadowElement) => {
                console.log(`Shadow Element Detected to Swap: ${shadowElement}\n`);
                const authHref = shadowElement.getAttribute('data-auth-href');
                if (authHref) {
                    shadowElement.setAttribute('href', authHref);
                    swappedLinks++;
                }
            });
        }
        console.log(`Swap Link Process Completed\nLinks Swapped: ${swappedLinks}`);
    }
}
