var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { component, prop } from '@gbds/core/src/ts/core';
import { PriceShortCodes, DisclosureShortCodes } from '../../../models/shortcodes';
import { RegionalDisclosure } from '../regional-disclosure/regional-disclosure';
import { RegionalPrice } from '../regional-price/regional-price';
let DynamicText = class DynamicText extends HTMLElement {
    constructor() {
        super();
    }
    onInit() {
        this._dynamicParagraphText = this.querySelector('.gb-dynamic-paragraph-text');
        this._replaceShortcodes(this._dynamicParagraphText);
        this._dynamicParagraphText.classList.remove('hide');
    }
    disconnectedCallback() {
    }
    _replaceShortcodes(paragraphText) {
        const paragraphElements = paragraphText.querySelectorAll('p');
        paragraphElements.forEach(p => {
            Object.values(PriceShortCodes).forEach(pshortcode => {
                var _a;
                if (((_a = p.textContent) === null || _a === void 0 ? void 0 : _a.indexOf(pshortcode)) > -1) {
                    const priceKey = this._convertToCamelCase(pshortcode.replace('{{', '').replace('}}', ''), '_');
                    const gbRegionalPriceElem = new RegionalPrice();
                    const shortcodeRegex = new RegExp(pshortcode, 'g');
                    Object.keys(this.regionalInformationJson).forEach(entry => {
                        if (this.regionalInformationJson[entry][priceKey])
                            gbRegionalPriceElem.setAttribute(entry.toLowerCase() + '-price', this.regionalInformationJson[entry][priceKey]);
                    });
                    gbRegionalPriceElem.classList.add('hide');
                    gbRegionalPriceElem.textContent = pshortcode;
                    this._dynamicParagraphText.innerHTML = this._dynamicParagraphText.innerHTML.replace(shortcodeRegex, gbRegionalPriceElem.outerHTML);
                }
            });
            Object.values(DisclosureShortCodes).forEach(dshortcode => {
                var _a;
                if (((_a = p.textContent) === null || _a === void 0 ? void 0 : _a.indexOf(dshortcode)) > -1) {
                    const disclosureKey = this._convertToCamelCase(dshortcode.replace('{{', '').replace('}}', ''), '_');
                    const gbRegionalDisclosureElem = new RegionalDisclosure();
                    const shortcodeRegex = new RegExp(dshortcode, 'g');
                    Object.keys(this.regionalInformationJson).forEach(entry => {
                        if (this.regionalInformationJson[entry][disclosureKey])
                            gbRegionalDisclosureElem.setAttribute(entry.toLowerCase() + '-disclosure', this.regionalInformationJson[entry][disclosureKey]);
                    });
                    gbRegionalDisclosureElem.classList.add('hide');
                    gbRegionalDisclosureElem.textContent = dshortcode;
                    this._dynamicParagraphText.innerHTML = this._dynamicParagraphText.innerHTML.replace(shortcodeRegex, gbRegionalDisclosureElem.outerHTML);
                }
            });
        });
    }
    _convertToCamelCase(str, delimiter) {
        const regex = new RegExp(`${delimiter}([a-z])`, 'g');
        return str.replace(regex, function (g) {
            return g[1].toUpperCase();
        });
    }
};
__decorate([
    prop('object')
], DynamicText.prototype, "regionalInformationJson", void 0);
DynamicText = __decorate([
    component('gb-dynamic-text')
], DynamicText);
export { DynamicText };
