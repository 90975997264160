export function debounce(callback, delay, immediate) {
    let timeout;
    let result;
    return function () {
        const args = arguments;
        const later = function () {
            timeout = null;
            if (!immediate) {
                result = callback.apply(this, args);
            }
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, delay);
        if (callNow) {
            result = callback.apply(this, args);
        }
        return result;
    };
}
