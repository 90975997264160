export const addClickPanListener = (marker, map, offsetX = 0, offsetY = 0) => {
    return marker.addListener('click', () => {
        if (offsetX || offsetY) {
            const scale = Math.pow(2, map.getZoom());
            const worldCoordinateCenter = map.getProjection().fromLatLngToPoint(marker.getPosition());
            const pixelOffset = new google.maps.Point(offsetX / scale || 0, offsetY / scale || 0);
            const worldCoordinateNewCenter = new google.maps.Point(worldCoordinateCenter.x - pixelOffset.x, worldCoordinateCenter.y + pixelOffset.y);
            const newOffsetLatLng = map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);
            map.panTo(newOffsetLatLng);
        }
        else {
            map.panTo(marker.getPosition());
        }
    });
};
export function addMarkersToMap(markers, map, clusterer) {
    if (Array.isArray(markers)) {
        markers.forEach(m => m.setMap(map));
        if (clusterer)
            clusterer.addMarkers(markers);
    }
    else {
        markers.setMap(map);
        if (clusterer)
            clusterer.addMarker(markers);
    }
}
export function clearMarkersFromMap(arg) {
    if (Array.isArray(arg)) {
        arg.forEach(m => m.setMap(null));
    }
    else {
        arg.clearMarkers();
    }
}
export const createMarker = (markerOpts) => {
    return new google.maps.Marker(markerOpts);
};
export function extendBounds(bounds, markers) {
    if (Array.isArray(markers)) {
        markers.forEach(m => bounds.extend(m.getPosition()));
    }
    else {
        bounds.extend(markers.getPosition());
    }
    return bounds;
}
export function isLatLngOrLiteral(location) {
    return location.lat !== undefined;
}
export const greyscaleTheme = [
    {
        elementType: 'geometry',
        stylers: [
            {
                color: '#f5f5f5',
            },
        ],
    },
    {
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#616161',
            },
        ],
    },
    {
        elementType: 'labels.text.stroke',
        stylers: [
            {
                color: '#f5f5f5',
            },
        ],
    },
    {
        featureType: 'administrative.country',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#262626',
            },
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#bdbdbd',
            },
        ],
    },
    {
        featureType: 'administrative.province',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#262626',
            },
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            {
                color: '#eeeeee',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#757575',
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e5e5e5',
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#9e9e9e',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#757575',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
            {
                color: '#dadada',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#616161',
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#9e9e9e',
            },
        ],
    },
    {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e5e5e5',
            },
        ],
    },
    {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
            {
                color: '#eeeeee',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            {
                color: '#c9c9c9',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#9e9e9e',
            },
        ],
    },
];
