var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { gbpeLoadedCheck } from '../portable-experiences';
import { parseTokenForEntitlements, getVerificationStatusFromToken } from '../auth/verify-token';
export class EntitlementService {
    constructor() {
        this.loaded = new Promise(resolve => {
            gbpeLoadedCheck(() => __awaiter(this, void 0, void 0, function* () {
                if (window.gbpe.auth) {
                    window.gbpe.auth.loaded.then(() => {
                        this._fetchEntitlements();
                        this._checkCelestiq();
                        resolve();
                    });
                }
            }));
        });
    }
    get entitlements() {
        return this._entitlements;
    }
    get isCelestiq() {
        return this._isCelestiq;
    }
    _fetchEntitlements() {
        if (window.gbpe.auth.currentAuthState === 1 && window.gbpe.auth.idToken) {
            this._getEntitlementsFromToken(window.gbpe.auth.idToken);
        }
    }
    _getEntitlementsFromToken(idToken) {
        const verifiedStatus = getVerificationStatusFromToken(idToken);
        const entitlement = parseTokenForEntitlements(idToken);
        this._verified = verifiedStatus ? true : false;
        this._entitlements = entitlement ? this._convertToList(entitlement) : null;
    }
    _convertToList(entitlement) {
        return entitlement.split(',');
    }
    _checkCelestiq() {
        const CELESTIQ_ENTITLEMENT = ['CELESTIQVIP', 'JDCONCIERGE'];
        this._isCelestiq = this.entitlements
            ? CELESTIQ_ENTITLEMENT.filter(item => this.entitlements.includes(item)).length > 0
                ? true
                : false
            : false;
    }
}
