import { ReplaySubject } from 'rxjs';
import { initRegionalContent } from './regional-content';
const RegionServiceLocalStorageKey = 'gmwp.region_select_cookie';
const CustomerLocationLocalStorageKey = 'gmwp.cookie_customer_location';
export class RegionService {
    constructor() {
        this._currentRegion$ = new ReplaySubject();
        this._initialized = false;
    }
    _checkLocalStorageForRegion() {
        const storedRegion = localStorage.getItem(RegionServiceLocalStorageKey);
        if (storedRegion) {
            try {
                const region = this._convertLocalStorageRegionToRegion(JSON.parse(storedRegion));
                return region;
            }
            catch (_a) {
                return false;
            }
        }
        else {
            return false;
        }
    }
    _convertRegionToCustomerCookie(region) {
        const cities = [], state = region.code, zipcode = '', latitude = '', longitude = '';
        return {
            cities: cities,
            state: state,
            zipcode: zipcode,
            latitude: latitude,
            longitude: longitude,
        };
    }
    _convertRegionToLocalStorageRegion(region) {
        return {
            regionCode: region.code,
        };
    }
    _convertLocalStorageRegionToRegion(region) {
        var _a;
        const regionLabel = (_a = this._availableRegions[region.regionCode]) === null || _a === void 0 ? void 0 : _a.label;
        return {
            code: region.regionCode,
            name: regionLabel,
        };
    }
    get isRegionCookieAvailable() {
        return localStorage.getItem(RegionServiceLocalStorageKey) ? true : false;
    }
    get isCustomerCookieAvailable() {
        return localStorage.getItem(CustomerLocationLocalStorageKey) ? true : false;
    }
    _getDefaultRegion() {
        const [key, region] = Object.entries(this._availableRegions).find(([, region]) => region.default);
        return { code: key, name: region.label };
    }
    _setLocalStorage(region) {
        const localStorageRegion = this._convertRegionToLocalStorageRegion(region);
        localStorage.setItem(RegionServiceLocalStorageKey, JSON.stringify(localStorageRegion));
        this._setCustomerLocationCookie(region);
    }
    _setRegion(region, setLocalStorage = true) {
        this._currentRegion$.next(region);
        if (setLocalStorage)
            this._setLocalStorage(region);
        document.dispatchEvent(new CustomEvent('gbds-selectable-list-event', {
            detail: Object.assign({}, region),
        }));
    }
    _setCustomerLocationCookie(region) {
        const localStorageCustomerLocation = this._convertRegionToCustomerCookie(region);
        localStorage.setItem(CustomerLocationLocalStorageKey, JSON.stringify(JSON.stringify(localStorageCustomerLocation)));
    }
    init(regions) {
        if (!this._initialized) {
            this._availableRegions = regions;
            const localStorageRegion = this._checkLocalStorageForRegion();
            if (localStorageRegion) {
                this._setRegion(localStorageRegion);
            }
            else {
                const defaultRegion = this._getDefaultRegion();
                this._setRegion(defaultRegion, false);
            }
            this._initialized = true;
            initRegionalContent();
        }
    }
    setRegion(regionKey) {
        var _a;
        const regionLabel = (_a = this._availableRegions[regionKey]) === null || _a === void 0 ? void 0 : _a.label;
        if (regionLabel) {
            const region = { code: regionKey, name: regionLabel };
            this._setRegion(region);
            return true;
        }
        else {
            return false;
        }
    }
    get currentRegion$() {
        return this._currentRegion$.asObservable();
    }
    get availableRegions() {
        if (this._initialized) {
            return this._availableRegions;
        }
        else {
            return undefined;
        }
    }
}
