var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AuthEvents, AuthState, AuthStorageKeys, clearUserInfo, verificationConstants, } from '../../config/auth-enumerations';
import { getRegionRedirect, getTimeToExpiration, getVerificationStatusFromToken, isTokenExpired, parseTokenForExpiration, redirectToVerificationPage, validateToken, } from './verify-token';
import { InactivityEvents, InactivityStorageKeys, InactivityTracker } from '../inactivity-tracker';
import { isAbsoluteUrl } from '@gbds/core/src/ts/utils';
import { requiredMIVal } from '../../config/script-attributes';
import { reportErrorToDynatrace } from '../dynatrace-error-logging';
export class CustomClientApplication {
    constructor(_urlConfig, required = false) {
        this._urlConfig = _urlConfig;
        this._idToken = this._getExternalIdToken();
        this._accessToken = this._getExternalAccessToken();
        const emailVerifiedProperty = getVerificationStatusFromToken(this._idToken);
        const emailVerified = emailVerifiedProperty && emailVerifiedProperty.toLowerCase() === verificationConstants.verified;
        if (!emailVerified) {
            this._handleUnverifiedUser();
        }
        this._validateTokens().then(value => {
            if (value === true) {
                this._setAuthenticated();
                this._setExpireTracker();
            }
            else {
                this._setUnAuthenticated();
            }
        });
    }
    _validateTokens() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (this._idToken) {
                    const idTokenValid = yield validateToken(this._idToken);
                    if (this._accessToken) {
                        const accessTokenValid = yield validateToken(this._accessToken);
                        return idTokenValid && accessTokenValid;
                    }
                    else {
                        console.warn('access token is not present');
                        return idTokenValid;
                    }
                }
                else {
                    console.warn('no id token to validate');
                    return false;
                }
            }
            catch (error) {
                console.log(error);
                reportErrorToDynatrace(error);
                return false;
            }
        });
    }
    _setAuthenticated() {
        this._authenticated = true;
        this._authState = AuthState.authenticated;
        this._dispatchAuthChecked();
        const customTimeout = sessionStorage.getItem(InactivityStorageKeys.customTimeOut);
        const timeLimit = customTimeout && !isNaN(parseInt(customTimeout)) && parseInt(customTimeout) <= 30 * 60 * 1000
            ? parseInt(customTimeout)
            : 30 * 60 * 1000;
        this._timer = new InactivityTracker(timeLimit);
        this._boundInactiveUserHandler = this._handleInactiveUser.bind(this);
        window.addEventListener(InactivityEvents.inactiveUser, this._boundInactiveUserHandler);
        this._boundStorageHandler = this._handleStorageEvent.bind(this);
        window.addEventListener('storage', this._boundStorageHandler);
    }
    _setUnAuthenticated() {
        this._authenticated = false;
        this._authState = AuthState.unAuthenticated;
        this._dispatchAuthChecked();
        this.logout();
    }
    _dispatchAuthChecked() {
        window.dispatchEvent(new CustomEvent(AuthEvents.loaded));
    }
    _getExternalIdToken() {
        if (sessionStorage.getItem(AuthStorageKeys.externalIdToken) !== '') {
            return sessionStorage.getItem(AuthStorageKeys.externalIdToken);
        }
        else {
            return null;
        }
    }
    _getExternalAccessToken() {
        if (sessionStorage.getItem(AuthStorageKeys.externalAccessToken) !== '') {
            return sessionStorage.getItem(AuthStorageKeys.externalAccessToken);
        }
        else {
            return null;
        }
    }
    _isTokenUnexpired(timeToCheck) {
        return __awaiter(this, void 0, void 0, function* () {
            return isTokenExpired(timeToCheck);
        });
    }
    _setExpireTracker() {
        return __awaiter(this, void 0, void 0, function* () {
            const idTokenDate = parseTokenForExpiration(this._idToken);
            const timeTilExpiration = yield getTimeToExpiration(idTokenDate);
            window.setTimeout(() => {
                this.logout();
            }, timeTilExpiration);
        });
    }
    _handleUnverifiedUser() {
        return __awaiter(this, void 0, void 0, function* () {
            const regionRedirect = yield getRegionRedirect(this._idToken, 'en-US', 'US', this._urlConfig.MyAccountProfileURL);
            redirectToVerificationPage(regionRedirect);
        });
    }
    _handleInactiveUser() {
        sessionStorage.setItem(InactivityStorageKeys.userSignedOut, 'true');
        this.logout();
    }
    _handleStorageEvent(event) {
        if (event.key === AuthStorageKeys.userLogout && event.newValue !== null) {
            this.logout();
        }
        else {
            if (event.key === AuthStorageKeys.userActive && event.newValue !== null) {
                this._timer.resetTimer();
            }
        }
    }
    _emitLocalStorageLogout() {
        localStorage.setItem(AuthStorageKeys.userLogout, 'true');
        localStorage.removeItem(AuthStorageKeys.userLogout);
    }
    loginRedirect(url, extraParams, forceLogin) {
        if (url) {
            if (extraParams) {
                const finalUrl = isAbsoluteUrl(url) ? new URL(url) : new URL(url, window.location.origin);
                let keyVal;
                for (keyVal in extraParams) {
                    finalUrl.searchParams.append(keyVal, extraParams[keyVal]);
                }
                window.location.href = finalUrl.href;
            }
            else {
                window.location.href = url;
            }
        }
        else {
            window.location.reload();
        }
    }
    logout(url) {
        sessionStorage.removeItem(AuthStorageKeys.externalIdToken);
        sessionStorage.removeItem(AuthStorageKeys.externalAccessToken);
        sessionStorage.removeItem(InactivityStorageKeys.customTimeOut);
        clearUserInfo(this._urlConfig);
        window.removeEventListener(InactivityEvents.inactiveUser, this._boundInactiveUserHandler);
        window.removeEventListener('storage', this._boundStorageHandler);
        this._emitLocalStorageLogout();
        const isSameDomain = url ? !isAbsoluteUrl(url) || new URL(url).origin === window.location.origin : false;
        if (url && isSameDomain) {
            window.location.href = url;
        }
        else {
            window.location.href = this._urlConfig.logoutURL;
        }
    }
    registerRedirect(url, stateURL) {
        if (url) {
            if (stateURL) {
                const stateURLQuery = '?state=' + stateURL;
                const finalURL = new URL(url + stateURLQuery);
                window.location.href = finalURL.href;
            }
            else {
                window.location.href = url;
            }
        }
        else {
            window.location.reload();
        }
    }
    get idToken() {
        return this._idToken;
    }
    get missingInfoRequired() {
        return requiredMIVal;
    }
    get authState() {
        return this._authState;
    }
    setInactiveTimeOut(timeout) {
        const inactiveTimeout = timeout.toString();
        sessionStorage.setItem(InactivityStorageKeys.customTimeOut, inactiveTimeout);
        window.location.reload();
    }
}
