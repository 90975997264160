import { HeadlessAuth } from './auth/authentication';
import { AuthStorageKeys } from '../config/auth-enumerations';
import { CustomClientApplication } from './auth/custom-client-application';
import { StandardClientApplication } from './auth/standard-client-application';
import { RegionService } from './region-service';
import { LanguageService } from './language-service';
import { MYACCOUNT_LOADED } from '../events';
import { AuthLinkService } from './auth-link-service';
export var PortableExperiencesEvents;
(function (PortableExperiencesEvents) {
    PortableExperiencesEvents["loaded"] = "gbpeLoaded";
})(PortableExperiencesEvents || (PortableExperiencesEvents = {}));
export function getBaseHeadlessAuth(config, urlConfig, sessionStoragePrefix) {
    const externalIdToken = sessionStorage.getItem(AuthStorageKeys.externalIdToken);
    if (externalIdToken) {
        return HeadlessAuth(new CustomClientApplication(urlConfig, config.authRequired), sessionStoragePrefix);
    }
    else {
        return HeadlessAuth(new StandardClientApplication(config, urlConfig, config.authRequired), sessionStoragePrefix);
    }
}
export const gbpeLoadedCheck = (callback) => {
    if (window.gbpe) {
        callback();
    }
    else {
        window.addEventListener(PortableExperiencesEvents.loaded, () => callback(), { once: true });
    }
};
export const gbpeContentLoadedCheck = (callback) => {
    window.addEventListener(MYACCOUNT_LOADED, () => callback(), { once: true });
};
export const PortableExperiences = (_authConfig, _userInfo, _headlessAuth, _overlay, _urlConfig, _entitlementService, _authContentService) => {
    let _auth = _headlessAuth;
    let _regionService = new RegionService();
    let _languageService = new LanguageService(_urlConfig);
    const _authLinkService = new AuthLinkService();
    let _config = _authConfig;
    const publicMembers = {};
    let finalized = false;
    function _finalize() {
        if (!finalized) {
            Object.seal(publicMembers);
            window.dispatchEvent(new CustomEvent(PortableExperiencesEvents.loaded));
            finalized = true;
        }
    }
    function getAuth() {
        return _auth;
    }
    function getConfig() {
        return _config;
    }
    function getOverlay() {
        return _overlay;
    }
    function getRegion() {
        return _regionService;
    }
    function getLanguage() {
        return _languageService;
    }
    function getUser() {
        return _userInfo;
    }
    function getEntitlementService() {
        return _entitlementService;
    }
    function getAuthContentService() {
        return _authContentService;
    }
    function getAuthLinkService() {
        return _authLinkService;
    }
    function setAuth(auth) {
        _auth = auth;
    }
    function setConfig(config) {
        _config = config;
    }
    function setOverlay(overlay) {
        _overlay = overlay;
    }
    function setRegion(region) {
        _regionService = region;
    }
    function setLanguage(languageService) {
        _languageService = languageService;
    }
    function setUser(userInfo) {
        _userInfo = userInfo;
    }
    function setEntitlementService(entitlementService) {
        _entitlementService = entitlementService;
    }
    function setAuthContentService(authContentService) {
        _authContentService = authContentService;
    }
    Object.defineProperties(publicMembers, {
        finalize: { value: _finalize },
        auth: { get: getAuth, set: setAuth },
        config: { get: getConfig, set: setConfig },
        overlay: { get: getOverlay, set: setOverlay },
        regionService: { get: getRegion, set: setRegion },
        user: { get: getUser, set: setUser },
        languageService: { get: getLanguage, set: setLanguage },
        entitlementService: { get: getEntitlementService, set: setEntitlementService },
        authContentService: { get: getAuthContentService, set: setAuthContentService },
        authLinkService: { get: getAuthLinkService },
    });
    return publicMembers;
};
