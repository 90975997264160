var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PortableAPI } from './portable-api';
export class CartAPI extends PortableAPI {
    getInterface() {
        return this.fetchCart();
    }
    fetchCart() {
        return __awaiter(this, void 0, void 0, function* () {
            yield window.gbpe.auth.loaded;
            if (window.gbpe.auth.currentAuthState !== 1) {
                return Promise.reject('User is unauthenticated !!!');
            }
            if (this._url === '' || this._url == undefined) {
                return Promise.resolve('Cart API Disabled for this Navigation: Cancelling API Call...');
            }
            return new Promise((resolve, reject) => {
                this._fetchPromise = this._mockEnabled
                    ? new Promise(this.getMockData())
                    : fetch(this._url, this.requestParameters());
                this._fetchPromise
                    .then(resp => {
                    if (resp && (resp['status'] === 200 || resp['status'] === 201)) {
                        return resp;
                    }
                    throw this.createError('Network error');
                })
                    .catch(() => {
                    throw this.createError('Network error');
                })
                    .then((resp) => __awaiter(this, void 0, void 0, function* () {
                    const respJson = yield resp['json']();
                    const ccInstance = new this[this._carbonCopyClassName](this, respJson, this._dependentInstance);
                    resolve(ccInstance);
                    this._lookupPromise = null;
                    console.log('API call for item :' + this._item);
                }))
                    .catch(error => {
                    this.createError(error);
                    reject(error);
                });
            });
        });
    }
    requestParameters() {
        const headers = new Headers({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.gbpe.auth.idToken}`,
        });
        return {
            method: 'POST',
            headers,
            body: '{"query":"query cart {\n\tcart {\n\t\torder {\n\t\t\titemCount\n\t\t}\n\t}\n}\n","operationName":"cart"}',
        };
    }
}
