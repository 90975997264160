import { animateClose, animateOpen } from '@gbds/core/src/ts/utils';
import styles from '@scss/overlay/overlay.scss';
import { unlockBodyScroll } from '../utils/body-scroll-lock';
export class Overlay {
    constructor(root, prepend = false) {
        this._zIndex = 122;
        this.closeOverlayOnClick = true;
        if (root) {
            this._initialize(root, prepend);
        }
        else {
            window.addEventListener('DOMContentLoaded', () => this._initialize(document.body, prepend), { once: true });
        }
    }
    _initialize(root, prepend) {
        const existingOverlay = root.querySelector('div.gb-overlay');
        if (!existingOverlay) {
            this._initializeOverlayElement(prepend, root);
        }
        else {
            console.warn('An `Overlay` component already exists in the light DOM of root: ', root);
        }
    }
    _initializeOverlayElement(prepend, root) {
        this._overlay = document.createElement('div');
        this._overlay.classList.add('gb-overlay');
        const style = document.createElement('style');
        style.textContent = styles.toString();
        this.setZHeight();
        this._insertStyles(prepend, root, style);
        this._addOverlayClickListener(this._overlay);
    }
    _insertStyles(prepend, root, styles) {
        prepend ? root.prepend(styles) : root.appendChild(styles);
        prepend ? root.insertBefore(this._overlay, styles.nextElementSibling) : root.appendChild(this._overlay);
    }
    _openOverlay() {
        animateOpen(this._overlay);
    }
    _addOverlayClickListener(overlay) {
        overlay.addEventListener('mousedown', e => {
            if (e.target === this._overlay && this.closeOverlayOnClick) {
                this.toggleOverlay();
                document.dispatchEvent(new CustomEvent('gb-overlayClosedFromClick'));
                e.preventDefault();
            }
        });
    }
    _closeOverlay() {
        animateClose(this._overlay);
        unlockBodyScroll();
    }
    addOverlayFinishAnimatingCallback(callback) {
        this._overlay.addEventListener('animationend', () => callback(), { once: true });
    }
    toggleOverlay() {
        this.overlayShown ? this._closeOverlay() : this._openOverlay();
    }
    get overlayShown() {
        return this._overlay.classList.contains('open');
    }
    get overlayAnimating() {
        return this._overlay.classList.contains('opening') || this._overlay.classList.contains('closing');
    }
    get zHeight() {
        return this._zIndex;
    }
    setZHeight(zIndex = 122) {
        this._zIndex = zIndex;
        this._overlay.style.zIndex = this._zIndex.toString();
        return this._zIndex;
    }
}
