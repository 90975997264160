var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { component } from '@gbds/core/src/ts/core';
import { getWindowSize, isVerticallyInViewport } from '../../../utils/windowSize';
import { debounce } from '@gbds/core/src/ts/utils';
import { imageLoader } from '../../../utils/image-lazy-loader';
var responsiveImageConstants;
(function (responsiveImageConstants) {
    responsiveImageConstants["fullHeightClass"] = "gb-full-height";
    responsiveImageConstants["centerClass"] = "gb-center-transform";
    responsiveImageConstants["lazyLoadAnimationClass"] = "gb-animated-fade-in";
    responsiveImageConstants["imageContainerSelector"] = ".gb-responsive-image-container";
    responsiveImageConstants["imageSelector"] = ".gb-image";
    responsiveImageConstants["dataEnableHeightControl"] = "data-enable-image-height-control";
    responsiveImageConstants["imageContainerWhenContentOverlaySelector"] = ".gb-image-overlay .gb-responsive-image-container";
    responsiveImageConstants["dataHeightPrefix"] = "data-height-";
    responsiveImageConstants["dataHeightDefault"] = "data-height-default";
    responsiveImageConstants["statImageLinkSelector"] = ".stat-image-link";
    responsiveImageConstants["contentOverlaySelector"] = ".gb-content-overlay";
    responsiveImageConstants["bacIdParam"] = "bacid";
})(responsiveImageConstants || (responsiveImageConstants = {}));
let ResponsiveImage = class ResponsiveImage extends HTMLElement {
    constructor() {
        super();
        this._isImageHeightControlEnabled = false;
        this._viewed = false;
    }
    onInit() {
        this._currentWindowSize = getWindowSize();
        if (this.querySelector(responsiveImageConstants.contentOverlaySelector) != null) {
            this._imageContainer = this.querySelector(responsiveImageConstants.imageContainerWhenContentOverlaySelector);
        }
        else {
            this._imageContainer = this.querySelector(responsiveImageConstants.imageContainerSelector);
        }
        this._image = this.querySelector(responsiveImageConstants.imageSelector);
        this._imageLink = this.getElementsByTagName('a')[0];
        if (this._imageContainer &&
            this._imageContainer.hasAttribute(responsiveImageConstants.dataEnableHeightControl)) {
            this._isImageHeightControlEnabled =
                this._imageContainer.getAttribute(responsiveImageConstants.dataEnableHeightControl) == 'true';
        }
        window.addEventListener('resize', debounce(this.onBroadcastWindowResize.bind(this), 250));
        window.addEventListener('scroll', debounce(this.onBroadcastWindowScroll.bind(this), 250));
        this._image.addEventListener('load', this.setImageContainerHeight.bind(this));
        if (document.readyState !== 'loading') {
            if (!this.hasAttribute('data-hq'))
                imageLoader(this);
        }
        else {
            window.addEventListener('DOMContentLoaded', () => {
                if (!this.hasAttribute('data-hq'))
                    imageLoader.bind(this);
            });
        }
    }
    setImageContainerHeight() {
        if (this._imageContainer == null) {
            return;
        }
        const heightControlDefault = this._imageContainer.getAttribute(responsiveImageConstants.dataHeightDefault) || '100%';
        const windowSizeHeight = responsiveImageConstants.dataHeightPrefix + getWindowSize();
        const heightControlTarget = this._imageContainer.getAttribute(windowSizeHeight) || heightControlDefault;
        const heightFinal = this.formatHeight(heightControlTarget);
        if (this._isImageHeightControlEnabled) {
            if (heightControlTarget.includes('%') && !heightControlTarget.includes('calc')) {
                this._imageContainer.setAttribute('style', 'height: ' + heightFinal + 'px');
            }
            else {
                this._imageContainer.setAttribute('style', 'height: ' + heightFinal);
            }
            this.onCropImage();
        }
    }
    formatHeight(height) {
        const imageHeight = getComputedStyle(this._image, null).height;
        if (('' + height).search(/%$/) > 0) {
            const heightNum = (+height.split('%')[0] / 100) * parseFloat(imageHeight);
            return heightNum + '';
        }
        return height;
    }
    onCropImage() {
        if (!this._image || !this._imageContainer) {
            return;
        }
        const imageHeight = +getComputedStyle(this._image, null).height;
        const imageWidth = +getComputedStyle(this._image, null).width;
        const imageContainerHeight = +getComputedStyle(this._imageContainer, null).height;
        const imageContainerWidth = +getComputedStyle(this._imageContainer, null).width;
        if (this._isImageHeightControlEnabled) {
            if (imageHeight < imageContainerHeight) {
                this._image.classList.add(responsiveImageConstants.fullHeightClass);
            }
            else if (imageWidth < imageContainerWidth) {
                this._image.classList.remove(responsiveImageConstants.fullHeightClass);
            }
            if (imageWidth == imageContainerWidth && imageHeight == imageContainerHeight) {
                this._image.classList.remove(responsiveImageConstants.centerClass);
            }
            else {
                this._image.classList.add(responsiveImageConstants.centerClass);
            }
        }
    }
    checkIfViewableAndAddAnimation() {
        const bodyElements = document.getElementsByTagName('body');
        let lazyLoadEnabled = false;
        if (bodyElements.item(0).hasAttribute('data-image-lazy-load-enabled')) {
            lazyLoadEnabled = bodyElements.item(0).getAttribute('data-image-lazy-load-enabled') == 'true';
        }
        if (lazyLoadEnabled && !this._viewed) {
            if (isVerticallyInViewport(this)) {
                this.classList.add(responsiveImageConstants.lazyLoadAnimationClass);
                this._viewed = true;
            }
        }
    }
    onBreakpointChange() {
        this.setImageContainerHeight();
    }
    onBroadcastWindowResize() {
        this.onCropImage();
        if (getWindowSize() != this._currentWindowSize) {
            this._currentWindowSize = getWindowSize();
            this.onBreakpointChange();
        }
    }
    onBroadcastWindowScroll() {
        this.checkIfViewableAndAddAnimation();
    }
};
ResponsiveImage = __decorate([
    component('gb-responsive-image')
], ResponsiveImage);
export { ResponsiveImage };
