export function isDefined(object) {
    if (object === undefined || object === null) {
        return false;
    }
    return true;
}
export function getPublisherUrl(domainName, relativePath) {
    relativePath = relativePath.replace(/^\//, '');
    return domainName + relativePath;
}
export function returnRTEHTML(rteString) {
    return document.createRange().createContextualFragment(rteString);
}
export function getFormattedNumber(unformattedString, locale) {
    return unformattedString ? new Intl.NumberFormat(locale).format(unformattedString) : 0;
}
export function sanitizeUrl(url) {
    return url.replace('[^-A-Za-z0-9+&@#/%?=~_|!:,.;()]', '');
}
export function isRTL() {
    return document.dir === 'rtl' ? true : false;
}
export function isWebViewApp() {
    var _a;
    return ((_a = navigator.userAgent.toLowerCase()) === null || _a === void 0 ? void 0 : _a.indexOf('webview')) != -1 ? true : false;
}
