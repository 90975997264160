var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as nav from './nav-core';
export function generateNav(navStyles, navDefaultVariant, myAccountFallbackImage, footerDefaultVariant, config, gbpeInitializationFunction, handleLanguageLinksPath) {
    gbpeInitializationFunction === null || gbpeInitializationFunction === void 0 ? void 0 : gbpeInitializationFunction.call(null, config);
    let Nav = class Nav extends nav.GlobalNav {
        constructor() {
            super(navStyles, navDefaultVariant, config, handleLanguageLinksPath);
        }
    };
    Nav = __decorate([
        nav.component('gb-global-nav')
    ], Nav);
    let MyAccNav = class MyAccNav extends nav.GbpeMyaccount {
        constructor() {
            super();
        }
    };
    MyAccNav = __decorate([
        nav.component('gb-myaccount-nav')
    ], MyAccNav);
    let MyAccFlyout = class MyAccFlyout extends nav.GbpeMyaccountFlyout {
        constructor() {
            super(myAccountFallbackImage, config);
        }
    };
    MyAccFlyout = __decorate([
        nav.component('gb-myaccount-flyout')
    ], MyAccFlyout);
    let Footer = class Footer extends nav.GbpeFooter {
        constructor() {
            super(navStyles, footerDefaultVariant);
        }
    };
    Footer = __decorate([
        nav.component('gb-global-footer')
    ], Footer);
}
