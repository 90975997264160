import { initUserData } from './user-info-config';
export var AuthLiterals;
(function (AuthLiterals) {
    AuthLiterals["UNAUTH"] = "UNAUTH";
    AuthLiterals["AUTH"] = "AUTH";
})(AuthLiterals || (AuthLiterals = {}));
export var AuthStorageKeys;
(function (AuthStorageKeys) {
    AuthStorageKeys["externalIdToken"] = "gbpe.auth.external.idToken";
    AuthStorageKeys["externalAccessToken"] = "gbpe.auth.external.accessToken";
    AuthStorageKeys["state"] = "gbpe.auth.state.param";
    AuthStorageKeys["clientID"] = "2816e68f-d202-4b18-9cc1-809160ffd363";
    AuthStorageKeys["forceLoginIndicator"] = "gbpe.auth.login.force";
    AuthStorageKeys["serverOffset"] = "gbpe.auth.server.offset";
    AuthStorageKeys["userActive"] = "gbpe.auth.user.active";
    AuthStorageKeys["userLogout"] = "gbpe.auth.user.logout";
    AuthStorageKeys["promptLogin"] = "gbpe.auth.login.prompt";
})(AuthStorageKeys || (AuthStorageKeys = {}));
export var AuthEvents;
(function (AuthEvents) {
    AuthEvents["loaded"] = "gbpe-authLoaded";
})(AuthEvents || (AuthEvents = {}));
export var AuthState;
(function (AuthState) {
    AuthState[AuthState["unAuthenticated"] = -1] = "unAuthenticated";
    AuthState[AuthState["authenticated"] = 1] = "authenticated";
})(AuthState || (AuthState = {}));
export var verificationConstants;
(function (verificationConstants) {
    verificationConstants["verified"] = "yes";
})(verificationConstants || (verificationConstants = {}));
export function clearSessionStorageOfGBPE() {
    sessionStorage.removeItem(AuthStorageKeys.externalIdToken);
    sessionStorage.removeItem(AuthStorageKeys.externalAccessToken);
    const keys = Object.keys(sessionStorage).filter(k => k.indexOf(AuthStorageKeys.clientID) !== -1);
    keys.forEach(k => sessionStorage.removeItem(k));
}
function clearSessionStorageStartingWith(startingString) {
    for (const key in sessionStorage) {
        if (key.startsWith(startingString)) {
            sessionStorage.removeItem(key);
        }
    }
}
export function clearUserInfo(config) {
    const userData = initUserData(config);
    sessionStorage.removeItem(userData.profile.storageKey);
    sessionStorage.removeItem(userData.rewards.storageKey);
    sessionStorage.removeItem(userData.vehicles.storageKey);
    sessionStorage.removeItem(userData.missingInfo.storageKey);
    clearSessionStorageStartingWith('GmOC');
}
