import { throttle } from '@gbds/core/src/ts/utils';
import { AuthStorageKeys } from '../config/auth-enumerations';
export var InactivityEvents;
(function (InactivityEvents) {
    InactivityEvents["inactiveUser"] = "gbpe-inactive-user";
})(InactivityEvents || (InactivityEvents = {}));
export var InactivityStorageKeys;
(function (InactivityStorageKeys) {
    InactivityStorageKeys["userSignedOut"] = "gbpe.inactivity.sign.out";
    InactivityStorageKeys["customTimeOut"] = "gbpe.auth.user.inactive.timeout";
})(InactivityStorageKeys || (InactivityStorageKeys = {}));
export class InactivityTracker {
    constructor(timeout) {
        this._timer = timeout;
        this._eventHandler = this.syncTimer.bind(this);
        this.tracker();
    }
    resetTimer() {
        clearTimeout(this._timerId);
        this._timerId = setTimeout(this.dispatchUserInactive, this._timer);
    }
    syncTimer() {
        this.emitActiveUser();
        this.resetTimer();
    }
    emitActiveUser() {
        localStorage.setItem(AuthStorageKeys.userActive, 'true');
        localStorage.removeItem(AuthStorageKeys.userActive);
    }
    dispatchUserInactive() {
        console.log(`Inactivity Logout Disabled`);
    }
    tracker() {
        window.addEventListener('load', throttle(this._eventHandler.bind(this), 1000));
        window.addEventListener('mousemove', throttle(this._eventHandler.bind(this), 1000));
        window.addEventListener('mousedown', throttle(this._eventHandler.bind(this), 1000));
        window.addEventListener('scroll', throttle(this._eventHandler.bind(this), 1000), true);
        window.addEventListener('keydown', throttle(this._eventHandler.bind(this), 1000));
        window.addEventListener('click', throttle(this._eventHandler.bind(this), 1000));
    }
}
