export const getNetStorageContentPath = (variation, suffix, baseOverride) => {
    const baseUrl = '/_gbpe/content-service/prod1/';
    const overrideEndsWithSlash = baseOverride === null || baseOverride === void 0 ? void 0 : baseOverride.endsWith('/');
    const correctedOverride = `${baseOverride}${!overrideEndsWithSlash ? '/' : ''}`;
    let url = '';
    if (baseOverride === null || baseOverride === void 0 ? void 0 : baseOverride.includes('experience-fragments')) {
        url = `${baseOverride}`;
    }
    else {
        url = `${baseOverride ? correctedOverride : baseUrl}${variation}${suffix}`;
    }
    return url;
};
