var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { component, GbdsElement, prop } from '@gbds/core/src/ts/core';
import { animateOpen, animateClose, throttle } from '@gbds/core/src/ts/utils';
import { isShiftTab, isTabOnly } from '@gbds/core/src/ts/utils';
import { getFirstAndLastFocusableElement } from '@gbds/core/src/ts/utils/firstlast-focusable-elements';
import { lockBodyScroll, unlockBodyScroll } from '../../utils/body-scroll-lock';
import { TabNavEvents } from '@gbds/core/src/ts/components/tab-nav';
import { isMediumViewport, isSmallViewport } from '@gbds/core/src/ts/utils/viewport';
import { isRTL } from '../../../../../gbds-core/src/ts/utils/helper-utils';
import { Overlay } from '../overlay';
var FlyoutEvents;
(function (FlyoutEvents) {
    FlyoutEvents["openingComplete"] = "gb-openFlyoutOpeningComplete";
    FlyoutEvents["closingComplete"] = "gb-openFlyoutClosingComplete";
})(FlyoutEvents || (FlyoutEvents = {}));
let Flyout = class Flyout extends GbdsElement {
    constructor() {
        super();
        this.loaded = new Promise(resolve => {
            this._resolver = resolve;
        });
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this._globalNav = document.querySelector('gb-global-nav');
            this._flyoutService = this._globalNav.flyoutService;
            this._parentButton = this._globalNav.shadowRoot.querySelector(`button[flyout="${this.flyoutId}"]`);
            this._isHamburgerFlyout = this.dataHamburgerMenu;
            if (this.childElementCount !== 0) {
                this._renderStatic();
            }
            else {
                yield this._renderDynamic();
            }
            if (this.slideOnMedium) {
                this._initSlideOnMedium();
            }
            else {
                this._closeButton = this._createCloseButton();
                this.appendChild(this._closeButton);
            }
            this._setFirstLastTabbableElements();
            this._addListeners();
            this._addFlyoutToService();
            if (this._isHamburgerFlyout) {
                const flyoutContainer = this._globalNav.shadowRoot.querySelector('.gb-flyouts-container');
                this._overlay = new Overlay(flyoutContainer);
            }
        });
    }
    _setFirstLastTabbableElements() {
        const nestedCompatibleTabNav = this.querySelector('.content-button-group');
        const isHamburgerMenuEnabled = this.getAttribute('data-hamburger-menu');
        const firstFocusableElementRoot = this;
        const lastFocusableElementRoot = this.slideOnMedium && !!nestedCompatibleTabNav ? nestedCompatibleTabNav : this;
        const { first } = getFirstAndLastFocusableElement(firstFocusableElementRoot);
        const { last } = getFirstAndLastFocusableElement(lastFocusableElementRoot);
        if (isHamburgerMenuEnabled === 'true') {
            this._firstFocusableElement = first || (this.slideOnMedium ? this._backButton : this._closeButton);
            this._lastFocusableElement = this._closeButton;
        }
        else {
            this._firstFocusableElement = first || (this.slideOnMedium ? this._backButton : this._closeButton);
            this._lastFocusableElement = last || (this.slideOnMedium ? this._backButton : this._closeButton);
        }
    }
    _setMoreFlyoutAlignment(isMoreFlyoutClosed) {
        const alignmentRight = 'alignment-right';
        const isOutOfViewport = !isRTL()
            ? this.getBoundingClientRect().right > innerWidth
            : this.getBoundingClientRect().left < 0;
        if (isMediumViewport() && this.isStackedMenu) {
            if (isMoreFlyoutClosed) {
                this.classList.remove(alignmentRight);
            }
            else if (isOutOfViewport) {
                this.classList.add(alignmentRight);
            }
        }
    }
    _renderStatic() {
        const template = this.firstElementChild;
        if (template.tagName === 'TEMPLATE') {
            const contents = template.content.cloneNode(true);
            this.appendChild(contents);
            template.remove();
            this._resolveRenderPromise();
        }
    }
    _renderDynamic() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.contentUrl) {
                const res = yield fetch(this.contentUrl);
                const rawHtml = yield res.text();
                this.innerHTML = rawHtml;
                this._resolveRenderPromise();
            }
        });
    }
    _resolveRenderPromise() {
        this._resolver(true);
    }
    _initSlideOnMedium() {
        this.classList.add('slide-medium');
        this._backButton = this._createBackButton();
        this.prepend(this._backButton);
    }
    _createBackButton() {
        const button = document.createElement('button');
        button.classList.add('gb-flyout-back', 'stat-text-link');
        button.setAttribute('data-dtm', this.backButtonDtm);
        button.setAttribute('data-dtm2', this.backButtonDtm2);
        button.textContent = this.backButtonLabel;
        return button;
    }
    _createCloseButton() {
        const button = document.createElement('button');
        button.classList.add('gb-flyout-close');
        button.textContent = this.dataHamburgerMenu ? '' : this.closeButtonLabel;
        return button;
    }
    _addListeners() {
        this._addClickListeners();
        this._addTabListeners();
        this._addOverlayCloseListener();
        this._addMyaccountListener();
        this._addParentbuttonHoverListener();
    }
    _addClickListeners() {
        this._addParentButtonClickListener();
        this._addCloseAndBackButtonClickListener();
    }
    _addParentbuttonHoverListener() {
        const hoverEnabled = this._parentButton.getAttribute('data-flyout-hover');
        if (!hoverEnabled) {
            return;
        }
        this._parentButton.addEventListener('mouseover', throttle(() => {
            if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
                return;
            }
            if (this.flyoutOpening) {
                this._addFlyoutOnAnimationEndListener(this.toggleFlyout);
            }
            else {
                this.toggleFlyout();
            }
        }, 500));
        this.addEventListener('mouseleave', throttle(() => {
            if (this.flyoutOpening) {
                this._addFlyoutOnAnimationEndListener(this.toggleFlyout);
            }
            else {
                this.toggleFlyout();
            }
        }, 500));
    }
    _addParentButtonClickListener() {
        this._parentButton.addEventListener('click', throttle(() => {
            if (this.flyoutOpening) {
                this._addFlyoutOnAnimationEndListener(this.toggleFlyout);
            }
            else {
                this.toggleFlyout();
            }
        }, 200));
    }
    _addCloseAndBackButtonClickListener() {
        var _a, _b;
        (_a = this._closeButton) === null || _a === void 0 ? void 0 : _a.addEventListener('click', () => {
            this.toggleFlyout();
            this._parentButton.focus();
        });
        (_b = this._backButton) === null || _b === void 0 ? void 0 : _b.addEventListener('click', () => {
            this.toggleFlyout();
            this._parentButton.focus();
        });
    }
    _addTabListeners() {
        this._parentButton.addEventListener('keydown', e => {
            this._handleParentButtonTabEvent(e);
            this._handleParentButtonShiftTabEvent(e);
        });
        if (this.slideOnMedium) {
            this._addBackButtonTabListeners();
        }
        else {
            this._addCloseButtonTabListeners();
        }
    }
    _handleParentButtonTabEvent(e) {
        if (isTabOnly(e) && (this.flyoutOpen || this.flyoutOpening)) {
            this._firstFocusableElement.focus();
            e.preventDefault();
        }
    }
    _handleParentButtonShiftTabEvent(e) {
        if (isShiftTab(e) && !this.flyoutOpening && this.flyoutOpen) {
            this.toggleFlyout();
        }
        else if (isShiftTab(e) && this.flyoutOpening) {
            this._addFlyoutOnAnimationEndListener(this.toggleFlyout);
        }
    }
    _addBackButtonTabListeners() {
        this._backButton.addEventListener('keydown', e => {
            if (this._isFirstFocusableElement(this._backButton)) {
                if (this._isLastFocusableElement(this._backButton))
                    this._handleCloseOrBackButtonTabEvent(e);
                this._handleFirstFocusableElementShiftTabEvent(e);
            }
            if (!this._isLastFocusableElement(this._backButton) && !this.slideOnMedium)
                this._handleBackButtonShiftTab(e);
        });
        if (!this._isFirstFocusableElement(this._backButton)) {
            this._firstFocusableElement.addEventListener('keydown', e => this._handleFirstFocusableElementShiftTabEvent(e));
        }
        if (!this._isLastFocusableElement(this._backButton)) {
            this._lastFocusableElement.addEventListener('keydown', e => this._handleLastFocusableElementTabEvent(e));
        }
        this._addFirstFocusableElementShiftTabListener(this._backButton);
    }
    _addCloseButtonTabListeners() {
        this._closeButton.addEventListener('keydown', e => {
            if (this._isFirstFocusableElement(this._closeButton))
                this._handleFirstFocusableElementShiftTabEvent(e);
            this._handleCloseOrBackButtonTabEvent(e);
        });
        this._addFirstFocusableElementShiftTabListener(this._closeButton);
    }
    _addFirstFocusableElementShiftTabListener(button) {
        if (!this._isFirstFocusableElement(button)) {
            this._firstFocusableElement.addEventListener('keydown', e => this._handleFirstFocusableElementShiftTabEvent(e));
        }
    }
    _handleBackButtonShiftTab(e) {
        if (isShiftTab(e)) {
            this._lastFocusableElement.focus();
            e.preventDefault();
        }
    }
    _handleCloseOrBackButtonTabEvent(e) {
        if (isTabOnly(e)) {
            if (!this.dataHamburgerMenu) {
                this.toggleFlyout();
                this._focusNextVisibleNavElement();
                e.preventDefault();
            }
            else {
                const firstFocusableElementRoot = this;
                const { first } = getFirstAndLastFocusableElement(firstFocusableElementRoot);
                first.focus();
                e.preventDefault();
            }
        }
    }
    _focusNextVisibleNavElement() {
        const getMiscNavFirstFocusableElement = () => {
            const miscNav = this._globalNav.shadowRoot.querySelector('.gn-misc');
            const { first } = getFirstAndLastFocusableElement(miscNav);
            return first;
        };
        const getMainContentFirstFocusableElement = () => {
            const first = this._globalNav.nextElementSibling;
            return first;
        };
        const findNextVisibleFocusableNavElement = () => {
            const stackedMenuContainer = this._globalNav.shadowRoot.querySelector('.stacked-menu-container');
            let nextSiblingVisible = false;
            let nextElementToCheck = this._parentButton.nextElementSibling;
            while (!nextSiblingVisible) {
                nextSiblingVisible = nextElementToCheck.offsetParent !== null;
                if (!nextSiblingVisible)
                    nextElementToCheck = nextElementToCheck.nextElementSibling;
                if (stackedMenuContainer.isSameNode(nextElementToCheck))
                    nextElementToCheck = stackedMenuContainer.firstElementChild;
                if (!nextElementToCheck) {
                    if (isSmallViewport() || isMediumViewport()) {
                        nextElementToCheck = getMainContentFirstFocusableElement();
                    }
                    else {
                        nextElementToCheck = getMiscNavFirstFocusableElement();
                    }
                }
            }
            return nextElementToCheck;
        };
        if (this.isStackedMenu) {
            if (isSmallViewport() || isMediumViewport()) {
                const elToFocus = getMainContentFirstFocusableElement();
                elToFocus.focus();
            }
            else {
                const elToFocus = getMiscNavFirstFocusableElement();
                elToFocus.focus();
            }
        }
        else {
            const elToFocus = findNextVisibleFocusableNavElement();
            elToFocus.focus();
        }
    }
    _handleFirstFocusableElementShiftTabEvent(e) {
        if (isShiftTab(e)) {
            this.toggleFlyout();
            this._parentButton.focus();
            e.preventDefault();
        }
    }
    _handleLastFocusableElementTabEvent(e) {
        if (isTabOnly(e)) {
            this._backButton.focus();
            e.preventDefault();
        }
    }
    _isFirstFocusableElement(el) {
        return el.isSameNode(this._firstFocusableElement);
    }
    _isLastFocusableElement(el) {
        return el.isSameNode(this._lastFocusableElement);
    }
    _addOverlayCloseListener() {
        document.addEventListener('gb-overlayClosedFromClick', () => {
            if (this.flyoutOpen) {
                this.toggleFlyout(true);
                this._parentButton.focus();
            }
            else if (this.flyoutOpening) {
                this._addFlyoutOnAnimationEndListener(this.toggleFlyout, true);
            }
        }, true);
    }
    _addMyaccountListener() {
        const modalEvents = ['gbpe-myAccountFlyout-open', 'gbpe-modal-open'];
        modalEvents.forEach(modalEvent => document.addEventListener(modalEvent, () => {
            if (this.flyoutOpen) {
                this.toggleFlyout();
            }
            else if (this.flyoutOpening) {
                this._addFlyoutOnAnimationEndListener(this.toggleFlyout);
            }
        }));
    }
    _addFlyoutOnAnimationEndListener(callback, ...args) {
        this.addEventListener('animationend', () => {
            callback.call(this, args);
        }, {
            once: true,
        });
    }
    _addFlyoutToService() {
        this._flyoutService.addFlyout(this, this.loaded);
    }
    _toggleAriaExpanded(button) {
        button.getAttribute('aria-expanded') === 'true'
            ? button.setAttribute('aria-expanded', 'false')
            : button.setAttribute('aria-expanded', 'true');
    }
    _toggleAriaHidden() {
        this.getAttribute('aria-hidden') === 'true'
            ? this.setAttribute('aria-hidden', 'false')
            : this.setAttribute('aria-hidden', 'true');
    }
    _openRegularFlyout(triggeredByOverlayClosed, triggeredByCloseOpenFlyout, triggeredByEscape) {
        const closedFlyoutId = this._flyoutService.closeOpenFlyout();
        if (closedFlyoutId !== this.flyoutId) {
            if (this.isStackedMenu && !isSmallViewport())
                this.style.height = this._getStackedMenuHeightStyle();
            this._openAndToggleOverflow(triggeredByOverlayClosed, closedFlyoutId ? true : triggeredByCloseOpenFlyout, triggeredByEscape);
            this._setFirstLastTabbableElements();
            this._setMoreFlyoutAlignment(false);
        }
    }
    _openSlideOnMediumFlyout(triggeredByOverlayClosed, triggeredByCloseOpenFlyout, triggeredByEscape) {
        const { flyoutHeight, navHeight } = this.flyoutAndNavHeight;
        this.parentElement.style.height = this._getStackedMenuHeightStyle();
        if (flyoutHeight + navHeight <= window.innerHeight) {
            this.parentElement.style.overflowY = 'hidden';
            const nestedTabNav = this.querySelector('gb-tab-nav');
            nestedTabNav === null || nestedTabNav === void 0 ? void 0 : nestedTabNav.addEventListener(TabNavEvents.stackedTabOpened, (e) => {
                const yPosition = this.getBoundingClientRect().y.toString() + 'px';
                this.parentElement.style.overflowY = '';
                e.detail.openContainer.style.top = yPosition;
                e.detail.openContainer.style.height = 'calc(100% - ' + yPosition + ')';
            });
            nestedTabNav === null || nestedTabNav === void 0 ? void 0 : nestedTabNav.addEventListener(TabNavEvents.stackedTabClosed, () => (this.parentElement.style.overflowY = 'hidden'));
        }
        this._openAndToggleOverflow(triggeredByOverlayClosed, triggeredByCloseOpenFlyout, triggeredByEscape);
        this._setFirstLastTabbableElements();
        this._backButton.focus();
    }
    _getStackedMenuHeightStyle() {
        const { flyoutHeight, navHeight } = this.flyoutAndNavHeight;
        return `min(calc(100vh - ${navHeight}px), ${flyoutHeight}px)`;
    }
    _openAndToggleOverflow(triggeredByOverlayClosed, triggeredByCloseOpenFlyout, triggeredByEscape) {
        lockBodyScroll();
        document.documentElement.style.setProperty('--nav-y', `${this._globalNav.getBoundingClientRect().y}px`);
        this._toggleOverlay(triggeredByOverlayClosed, triggeredByCloseOpenFlyout, triggeredByEscape);
        animateOpen(this, () => document.dispatchEvent(new CustomEvent(FlyoutEvents.openingComplete)));
    }
    _closeFlyout(triggeredByCloseOpenFlyout, triggeredByOverlayClosed, triggeredByEscape) {
        const disclosureOpen = document.querySelector('.gb-disclosure-open');
        if (!disclosureOpen) {
            lockBodyScroll();
            this._toggleOverlay(triggeredByOverlayClosed, triggeredByCloseOpenFlyout, triggeredByEscape);
            animateClose(this, () => {
                if (this.isStackedMenu && this._flyoutService.flyoutOpen) {
                    this._flyoutService.closeOpenFlyout();
                }
                const { flyoutHeight, navHeight } = this.flyoutAndNavHeight;
                if (flyoutHeight + navHeight <= window.innerHeight) {
                    const nestedTabNav = this.querySelector('gb-tab-nav');
                    nestedTabNav === null || nestedTabNav === void 0 ? void 0 : nestedTabNav.removeEventListener(TabNavEvents.stackedTabOpened, (e) => {
                        const yPosition = this.getBoundingClientRect().y.toString() + 'px';
                        this.parentElement.style.overflowY = '';
                        e.detail.openContainer.style.top = yPosition;
                        e.detail.openContainer.style.height = 'calc(100% - ' + yPosition + ')';
                    });
                    nestedTabNav === null || nestedTabNav === void 0 ? void 0 : nestedTabNav.removeEventListener(TabNavEvents.stackedTabClosed, () => (this.parentElement.style.overflowY = 'hidden'));
                }
                if (this.querySelectorAll('gb-tab-nav .content-container.open').length > 0) {
                    this.querySelectorAll('gb-tab-nav .content-container.open').forEach(cnt => cnt.classList.remove('open'));
                }
                const myAccountMenu = this._globalNav.shadowRoot.querySelector('gb-myaccount-nav');
                const myAccountMenuOpen = myAccountMenu ? myAccountMenu.getAttribute('flyout') === 'open' : false;
                if (triggeredByEscape || (!triggeredByCloseOpenFlyout && !this.slideOnMedium && !myAccountMenuOpen)) {
                    unlockBodyScroll();
                }
                else if (!triggeredByCloseOpenFlyout) {
                    this._parentButton.focus();
                }
                if (this.slideOnMedium) {
                    this.parentElement.style.overflowY = '';
                    this.parentElement.style.height = '';
                }
                if (this.isStackedMenu)
                    this.style.height = '';
                document.dispatchEvent(new CustomEvent(FlyoutEvents.closingComplete));
                this._setMoreFlyoutAlignment(true);
                if (this.dataHamburgerMenu) {
                    this.querySelector('.gb-flyout-close').classList.remove('gb-in-stacked-hb-close');
                    this.style.visibility = '';
                }
            });
        }
        else if (!this._isHamburgerFlyout) {
            window.gbpe.overlay.toggleOverlay();
        }
    }
    _toggleOverlay(triggeredByOverlayClosed, triggeredByCloseOpenFlyout, triggeredByEscape) {
        const triggeredByOverlayClosedBool = typeof triggeredByOverlayClosed === 'object'
            ? triggeredByOverlayClosed.length > 0
            : triggeredByOverlayClosed;
        const overlay = window.gbpe.overlay;
        if (triggeredByEscape ||
            (!triggeredByOverlayClosedBool && !this.slideOnMedium && !triggeredByCloseOpenFlyout)) {
            if (overlay.overlayAnimating) {
                overlay.addOverlayFinishAnimatingCallback(() => overlay.toggleOverlay());
            }
            else if (!this._isHamburgerFlyout) {
                overlay.toggleOverlay();
            }
        }
        if (this._isHamburgerFlyout &&
            !triggeredByEscape &&
            !triggeredByOverlayClosedBool &&
            triggeredByCloseOpenFlyout) {
            overlay.toggleOverlay();
        }
    }
    toggleFlyout(triggeredByOverlayClosed = false, triggeredByCloseOpenFlyout = false, triggeredByEscape = false) {
        const openSlideOrRegularFlyout = () => {
            if (this.slideOnMedium) {
                this._openSlideOnMediumFlyout(triggeredByOverlayClosed, triggeredByCloseOpenFlyout, triggeredByEscape);
            }
            else {
                this._openRegularFlyout(triggeredByOverlayClosed, triggeredByCloseOpenFlyout, triggeredByEscape);
            }
        };
        window.gbpe.overlay.setZHeight(122);
        if (this.flyoutOpen) {
            this._closeFlyout(triggeredByCloseOpenFlyout, triggeredByOverlayClosed, triggeredByEscape);
        }
        else if (this._flyoutService.flyoutOpening) {
            document.addEventListener(FlyoutEvents.openingComplete, openSlideOrRegularFlyout, { once: true });
        }
        else {
            openSlideOrRegularFlyout();
        }
        if (triggeredByOverlayClosed && this.slideOnMedium) {
            const containingFlyout = this.parentElement;
            containingFlyout.flyoutParentButton.focus();
        }
        this._toggleAriaExpanded(this._parentButton);
        this._toggleAriaHidden();
        if (this._isHamburgerFlyout) {
            if (!triggeredByOverlayClosed) {
                this._overlay.toggleOverlay();
            }
        }
    }
    get flyoutAndNavHeight() {
        const flyoutHeight = this.getBoundingClientRect().height;
        const navHeight = this._globalNav.getBoundingClientRect().height;
        return { flyoutHeight, navHeight };
    }
    get isStackedMenu() {
        return this.classList.contains('stacked-flyout');
    }
    get flyoutClosing() {
        return this.classList.contains('closing');
    }
    get flyoutOpen() {
        return this.classList.contains('open');
    }
    get flyoutOpening() {
        return this.classList.contains('opening');
    }
    get flyoutParentButton() {
        return this._parentButton;
    }
};
__decorate([
    prop()
], Flyout.prototype, "backButtonLabel", void 0);
__decorate([
    prop()
], Flyout.prototype, "closeButtonLabel", void 0);
__decorate([
    prop()
], Flyout.prototype, "dataHamburgerMenu", void 0);
__decorate([
    prop()
], Flyout.prototype, "contentUrl", void 0);
__decorate([
    prop('boolean')
], Flyout.prototype, "slideOnMedium", void 0);
__decorate([
    prop()
], Flyout.prototype, "flyoutId", void 0);
__decorate([
    prop()
], Flyout.prototype, "backButtonDtm", void 0);
__decorate([
    prop()
], Flyout.prototype, "backButtonDtm2", void 0);
Flyout = __decorate([
    component('gb-flyout')
], Flyout);
export { Flyout };
