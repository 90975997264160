export const GBPE_NAMESPACE = 'GBPE_API_CACHE_';
export var UserInfoItems;
(function (UserInfoItems) {
    UserInfoItems["profile"] = "profile";
    UserInfoItems["vehicles"] = "vehicles";
    UserInfoItems["rewards"] = "rewards";
    UserInfoItems["missingInfo"] = "missingInfo";
    UserInfoItems["notifications"] = "notifications";
    UserInfoItems["cart"] = "cart";
    UserInfoItems["koreaProfileRefresh"] = "koreaProfileRefresh";
    UserInfoItems["koreaCheckJson"] = "koreaCheckJson";
})(UserInfoItems || (UserInfoItems = {}));
export const RewardsStatus = ['open', 'suspended', 'closed', 'active', '200'];
export var RewardsState;
(function (RewardsState) {
    RewardsState["active"] = "open";
    RewardsState["closed"] = "closed";
    RewardsState["inactive"] = "inactive";
    RewardsState["other"] = "other";
})(RewardsState || (RewardsState = {}));
