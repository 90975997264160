var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { INITIAL_RENDER } from '../events';
export class FlyoutService {
    constructor(nav) {
        this.allFlyoutsLoaded = new Promise(resolve => {
            this._resolver = resolve;
        });
        this._flyoutLoadedPromises = [];
        this._flyouts = new Map();
        this._addEscapeListener();
        nav.addEventListener(INITIAL_RENDER, () => this._initFlyoutsLoadedPromise(nav), { once: true });
    }
    _addEscapeListener() {
        document.addEventListener('keyup', e => this._escapeListenerHandler(e), true);
    }
    _escapeListenerHandler(e) {
        const disclosureOpen = document.querySelector('.gb-disclosure-open');
        if (e.key === 'Escape') {
            if (!disclosureOpen) {
                this.closeOpenFlyout(false, true);
                e.preventDefault();
            }
            else {
                window.gbpe.overlay.setZHeight(122);
            }
        }
    }
    _initFlyoutsLoadedPromise(nav) {
        const numberOfFlyouts = nav.shadowRoot.querySelectorAll('gb-flyout').length;
        const interval = setInterval(() => __awaiter(this, void 0, void 0, function* () {
            if (this._flyoutLoadedPromises.length === numberOfFlyouts) {
                yield Promise.all(this._flyoutLoadedPromises);
                this._resolver(true);
                clearInterval(interval);
            }
        }), 50);
    }
    get _openFlyout() {
        const openFlyouts = Array.from(this._flyouts.values()).filter(f => f.flyoutOpen);
        if (openFlyouts.length > 1) {
            return openFlyouts[1];
        }
        else {
            return openFlyouts[0];
        }
    }
    get flyoutOpen() {
        return !!this._openFlyout;
    }
    get flyoutOpening() {
        return Array.from(this._flyouts.values()).find(f => f.flyoutOpening);
    }
    closeOpenFlyout(triggeredByOverlayClose = false, triggeredByEscape = false) {
        const openFlyout = this._openFlyout;
        if (openFlyout) {
            openFlyout.toggleFlyout(triggeredByOverlayClose, true, triggeredByEscape);
            return openFlyout.flyoutId;
        }
    }
    addFlyout(flyout, loaded) {
        this._flyouts.set(flyout.flyoutId, flyout);
        this._flyoutLoadedPromises.push(loaded);
    }
}
