var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { component, prop } from '@gbds/core/src/ts/core';
let Wrapper = class Wrapper extends HTMLElement {
    constructor() {
        super();
    }
    onInit() {
        if (this.dataLinkType == 'targetZone') {
            this.buttonEl = this.querySelector('button');
            this.buttonEl.onclick = e => {
                this.changeTargetZone(e);
            };
        }
    }
    changeTargetZone(e) {
        e.preventDefault();
        e.stopPropagation();
        const eventOpts = {
            bubbles: true,
            cancelable: false,
            detail: {
                zoneID: this.buttonEl.dataset.targetZoneId,
                targetZoneID: this.buttonEl.dataset.targetZoneSectionId,
            },
        };
        const evt = new CustomEvent('targetZoneChange', eventOpts);
        window.dispatchEvent(evt);
    }
};
__decorate([
    prop()
], Wrapper.prototype, "dataLinkType", void 0);
Wrapper = __decorate([
    component('gb-wrapper')
], Wrapper);
export { Wrapper };
