export var HeadlessAuthLoginBrands;
(function (HeadlessAuthLoginBrands) {
    HeadlessAuthLoginBrands["buick"] = "buick";
    HeadlessAuthLoginBrands["cadillac"] = "cadillac";
    HeadlessAuthLoginBrands["chevrolet"] = "chevrolet";
    HeadlessAuthLoginBrands["chevroletEV"] = "chevroletEV";
    HeadlessAuthLoginBrands["gmc"] = "gmc";
    HeadlessAuthLoginBrands["crossbrand"] = "GM";
    HeadlessAuthLoginBrands["celestiq"] = "celestiq";
    HeadlessAuthLoginBrands["ultium"] = "ultium";
    HeadlessAuthLoginBrands["multibrand"] = "multibrand";
})(HeadlessAuthLoginBrands || (HeadlessAuthLoginBrands = {}));
