import { MSalConfig } from '../../../config/msal-configs/base/msal-config-test';
export class NavConfig {
    constructor() {
        this._channel = 'globalnav';
        this._direction = 'ltr';
        this._urls = {
            MyAccountProfileURL: '/api/_gbpe/v3/profiles',
            MyAccountRewardsURL: '',
            MyAccountVehicleURL: '',
            MyAccountMissingInfoURL: '',
            NotificationInboxURL: '/notif/delivery/inbox/v1/customer',
            CartURL: '',
            KoreanProfileRefreshURL: '',
            KoreanCheckJsonURL: '',
            logoutURL: location.origin,
        };
        this._auth = Object.assign(Object.assign({}, MSalConfig), { authScopes: ['openid', 'profile'] });
    }
    get urls() {
        return this._urls;
    }
    get region() {
        return this._region;
    }
    get direction() {
        return this._direction;
    }
    get language() {
        return this._language;
    }
    get auth() {
        return this._auth;
    }
    get brand() {
        return this._brand;
    }
    get channel() {
        return this._channel;
    }
}
