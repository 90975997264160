import { GBPE_NAMESPACE, UserInfoItems } from '../models/user-info';
import { getKoreaDate } from '../utils/dates';
export function initUserData(urlConfig) {
    return {
        profile: {
            item: UserInfoItems.profile,
            url: urlConfig.MyAccountProfileURL,
            storageKey: GBPE_NAMESPACE + 'PROFILE',
            mockEnabled: false,
            bearerToken: false,
            mock: {
                status: 'SUCCESS',
                first_name: 'FirstName',
                last_name: 'LastName',
                email_address: 'FirstName.LastName@test.com',
                login_id: 'FirstName.LastName@test.com',
                onStar: {
                    accountId: ['test'],
                },
                phones: [
                    {
                        phone_number: '',
                        phone_type: '',
                    },
                ],
            },
        },
        rewards: {
            item: UserInfoItems.rewards,
            url: urlConfig.MyAccountRewardsURL,
            storageKey: GBPE_NAMESPACE + 'REWARDS',
            mockEnabled: false,
            bearerToken: false,
            mock: {
                reward: {
                    points: '20,000',
                    tier: 'Silver',
                    RespCode: '200',
                    status: 'Active',
                },
            },
        },
        vehicles: {
            item: UserInfoItems.vehicles,
            url: urlConfig.MyAccountVehicleURL,
            storageKey: GBPE_NAMESPACE + 'VEHICLES',
            mockEnabled: false,
            bearerToken: false,
            mock: {
                garage: [
                    {
                        link: '/myvehicle?vin=test',
                        title: 'CADILLAC',
                        imgUrl: 'test',
                    },
                ],
            },
        },
        missingInfo: {
            item: UserInfoItems.missingInfo,
            url: urlConfig.MyAccountMissingInfoURL,
            urlList: [urlConfig.MyAccountMissingInfoURL],
            storageKey: GBPE_NAMESPACE + 'MISSINGINFO',
            mockEnabled: false,
            bearerToken: false,
            mock: {
                actionRequired: [],
            },
        },
        notifications: {
            item: UserInfoItems.notifications,
            url: urlConfig.NotificationInboxURL,
            mockEnabled: true,
            bearerToken: false,
            mock: {
                notificationContent: [
                    {
                        msgId: 'string',
                        vin: 'string',
                        isRead: true,
                        msgReceivedTime: 'string',
                        messageContent: {
                            content: {
                                title: 'string',
                                description: 'string',
                                isHighPriority: true,
                                contentParams: [
                                    {
                                        body: 'string',
                                        buttonLabel: 'string',
                                        cta_handler: 'string',
                                        cta_type: 'DEEPLINK',
                                    },
                                ],
                            },
                        },
                    },
                ],
                next: 0,
                size: 0,
                unreadCount: 2,
            },
        },
        cart: {
            item: UserInfoItems.cart,
            url: urlConfig === null || urlConfig === void 0 ? void 0 : urlConfig.CartURL,
            mockEnabled: false,
            bearerToken: false,
            mock: {
                data: {
                    cart: {
                        order: {
                            itemCount: '2',
                        },
                    },
                },
            },
        },
        koreaProfileRefresh: {
            item: UserInfoItems.koreaProfileRefresh,
            url: urlConfig === null || urlConfig === void 0 ? void 0 : urlConfig.KoreanProfileRefreshURL,
            mockEnabled: false,
            bearerToken: false,
            mock: {
                cart: {
                    order: {
                        itemCount: '2',
                    },
                },
            },
        },
        koreaCheckJson: {
            item: UserInfoItems.koreaCheckJson,
            url: urlConfig === null || urlConfig === void 0 ? void 0 : urlConfig.KoreanCheckJsonURL,
            mockEnabled: false,
            bearerToken: false,
            mock: {
                cart: {
                    order: {
                        itemCount: '2',
                    },
                },
            },
        },
    };
}
export function initKoreaUserData(urlConfig) {
    const userDataBase = initUserData(urlConfig);
    const reqSysCode = 'QUANTUM';
    const reqJobCode = 'PROFILE';
    const checkDataDate = new Date();
    const checkDataString = getKoreaDate(checkDataDate);
    const encryptedDate = btoa(checkDataString);
    const sysQueryParam = `reqSysCode=${reqSysCode}`;
    const jobQueryParam = `reqJobCode=${reqJobCode}`;
    const dataQueryParam = `checkData=${encryptedDate}`;
    userDataBase.profile.url = userDataBase.profile.url + `?${sysQueryParam}&${jobQueryParam}&${dataQueryParam}`;
    return userDataBase;
}
