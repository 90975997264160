var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { brandContentConfigData, defaultConfigData, gbpeCelestiqModalActivated, } from '../../../src/ts/config/auth-content-config';
import { gbpeContentLoadedCheck } from './portable-experiences';
import { getBrandFromUrl, isBrandSite, isNonCelestiqPage } from './quantum';
import { GBModalService } from '@gbds/quantum/src/ts/utils/modal-service';
export class AuthContentService {
    constructor() {
        this._loadConfigFileContents();
        gbpeContentLoadedCheck(() => {
            this._initService();
        });
    }
    _loadConfigFileContents() {
        const _brand = getBrandFromUrl();
        this._getDefaultConfiguration();
        this._getBrandConfiguration(_brand);
    }
    _initService() {
        return __awaiter(this, void 0, void 0, function* () {
            this._globalNav = document.querySelector('gb-global-nav');
            this._evaluateAttributeCallback();
        });
    }
    _getDefaultConfiguration() {
        this._configEntries = Object.values(defaultConfigData);
    }
    _getBrandConfiguration(brand) {
        var _a;
        const brandConfigEntries = (_a = Object.values(brandContentConfigData).filter(configEntry => {
            if (configEntry.brand === brand)
                return configEntry;
        })[0]) === null || _a === void 0 ? void 0 : _a.config;
        brandConfigEntries ? (this._configEntries = this._configEntries.concat(brandConfigEntries)) : '';
    }
    _evaluateAttributeCallback() {
        var _a;
        (_a = this._configEntries) === null || _a === void 0 ? void 0 : _a.forEach(config => {
            this[config.callback] ? this[config.callback](config) : '';
        });
    }
    _validateAuthLinks() {
        this._isAttributeActive(this.getConfigFileRecord('auth'), this._isAuth());
    }
    _validateUnauthLinks() {
        this._isAttributeActive(this.getConfigFileRecord('unauth'), !this._isAuth());
    }
    _validateRewardsLinks() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this._isAuth()) {
                this._isAttributeActive(this.getConfigFileRecord('rewards'), yield this._isRewardsEnabled());
            }
        });
    }
    _validateNonRewardsLinks() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this._isAuth()) {
                this._isAttributeActive(this.getConfigFileRecord('nonrewards'), !(yield this._isRewardsEnabled()));
            }
        });
    }
    _validateCelestiqLinks() {
        return __awaiter(this, void 0, void 0, function* () {
            this._isAttributeActive(this.getConfigFileRecord('celestiq'), yield this.isCelestiqUser());
            if (this._canOpenModal() &&
                (yield this.isCelestiqUser()) &&
                (globalThis === null || globalThis === void 0 ? void 0 : globalThis.gmwp) &&
                isBrandSite() &&
                isNonCelestiqPage()) {
                this.triggerCelestiqModal();
                this._setLocalStorage();
            }
        });
    }
    triggerCelestiqModal() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            const celestiqModalContent = (_b = (_a = document === null || document === void 0 ? void 0 : document.querySelector('gb-global-nav')) === null || _a === void 0 ? void 0 : _a.shadowRoot.querySelector('[data-celestiq-modal-partial]')) === null || _b === void 0 ? void 0 : _b.getAttribute('data-celestiq-modal-partial');
            if ((yield this.isCelestiqUser()) && celestiqModalContent) {
                const celestiqJsonDetail = JSON.parse(celestiqModalContent);
                try {
                    GBModalService.openModal({
                        modalId: 'celestiq-auth-modal',
                        title: celestiqJsonDetail.title || '',
                        contentPath: `${celestiqJsonDetail.url}.html`,
                        isFullscreen: celestiqJsonDetail.fullScreenModal,
                    });
                }
                catch (error) {
                    console.log(error);
                }
            }
        });
    }
    _setLocalStorage() {
        localStorage.setItem(gbpeCelestiqModalActivated, 'true');
    }
    _canOpenModal() {
        return localStorage.getItem(gbpeCelestiqModalActivated) === 'true' ? false : true;
    }
    _isAttributeActive(authContentValue, display = false) {
        this._setDisplay(authContentValue, display);
        display ? this._setAttributesInDOM(authContentValue.attributeName) : '';
    }
    _setDisplay(authContentValue, display) {
        authContentValue.display = display ? 'show' : 'hide';
    }
    _setAttributesInDOM(attibuteName) {
        if (isBrandSite()) {
            this._setBodyAttribute(attibuteName);
        }
        this._setGlobalNavAttribute(attibuteName);
    }
    _setBodyAttribute(attibuteName) {
        this._setDataAttribute(document.body, attibuteName);
    }
    _setGlobalNavAttribute(attibuteName) {
        this._setDataAttribute(this._globalNav, attibuteName);
    }
    _setDataAttribute(container, attibuteName) {
        let attributeList = container === null || container === void 0 ? void 0 : container.getAttribute('data-auth-content-active');
        attributeList = attributeList ? `${attributeList};${attibuteName}` : attibuteName;
        container === null || container === void 0 ? void 0 : container.setAttribute('data-auth-content-active', attributeList);
    }
    _isAuth() {
        const isAuth = window.gbpe.auth.currentAuthState === 1 ? true : false;
        return isAuth;
    }
    _isRewardsEnabled() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d;
            const _rewards = yield window.gbpe.user.rewards;
            const isRewardsUser = ((_b = (_a = _rewards === null || _rewards === void 0 ? void 0 : _rewards.data) === null || _a === void 0 ? void 0 : _a.reward) === null || _b === void 0 ? void 0 : _b.status) && ((_d = (_c = _rewards === null || _rewards === void 0 ? void 0 : _rewards.data) === null || _c === void 0 ? void 0 : _c.reward) === null || _d === void 0 ? void 0 : _d.status) === 'Active' ? true : false;
            return isRewardsUser;
        });
    }
    isCelestiqUser() {
        return __awaiter(this, void 0, void 0, function* () {
            yield window.gbpe.entitlementService.loaded;
            return window.gbpe.entitlementService.isCelestiq;
        });
    }
    get data() {
        return this._configEntries;
    }
    getConfigFileRecord(name) {
        var _a;
        const configVal = (_a = this._configEntries) === null || _a === void 0 ? void 0 : _a.filter(config => (config === null || config === void 0 ? void 0 : config.name) === name)[0];
        return configVal;
    }
}
