import { pascalToKebabCase } from '../utils/string-case';
import { AttributeParser } from '../utils/attribute-parser';
export function prop(type = 'string', changedFn = null) {
    return (target, key) => {
        const attributeName = pascalToKebabCase(key);
        if (changedFn) {
            if (target.hasOwnProperty(changedFn)) {
                target.constructor.observedProps.set(attributeName, { type, changedFn });
            }
            else {
                throw new Error(`Member \`${changedFn}\` does not exist on on target class.`);
            }
        }
        const getter = function () {
            return AttributeParser.fromAttribute(this.getAttribute(attributeName), type);
        };
        const setter = function (newVal) {
            this.setAttribute(attributeName, AttributeParser.toAttribute(newVal, type));
        };
        if (delete target[key]) {
            Object.defineProperty(target, key, {
                get: getter,
                set: setter,
                enumerable: true,
            });
        }
    };
}
