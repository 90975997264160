export function getKoreaDate(date) {
    const checkDataDate = date;
    let checkDataString = '' + checkDataDate.getFullYear();
    const monthNum = checkDataDate.getMonth() + 1;
    const monthString = monthNum < 10 ? '0' + monthNum : '' + monthNum;
    checkDataString += '-' + monthString;
    checkDataString += '-' + checkDataDate.getDate();
    checkDataString += ' ' + checkDataDate.getHours();
    checkDataString += ':' + checkDataDate.getMinutes();
    const secondNum = checkDataDate.getSeconds();
    const secondString = secondNum < 10 ? '0' + secondNum : '' + secondNum;
    checkDataString += ':' + secondString;
    return checkDataString;
}
