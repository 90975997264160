import { debounce } from './debounce';
window['gmVhListenerAdded'] =
    window['gmVhListenerAdded'] ||
        (() => {
            const setVh = () => {
                const vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty('--vh', `${vh}px`);
            };
            window.addEventListener('load', debounce(setVh, 200), { once: true });
            window.addEventListener('resize', debounce(setVh, 200));
            return true;
        })();
export const animateOpen = (container, callback) => {
    const animationEndCallback = () => {
        container.classList.remove('opening');
        container.classList.add('open');
        if (callback)
            callback();
    };
    container.addEventListener('animationend', animationEndCallback, {
        once: true,
    });
    container.classList.add('opening');
    return animationEndCallback;
};
export const animateClose = (container, callback) => {
    const animationEndCallback = () => {
        container.classList.remove('closing');
        if (callback)
            callback();
    };
    container.addEventListener('animationend', animationEndCallback, {
        once: true,
    });
    container.classList.remove('open');
    container.classList.add('closing');
    return animationEndCallback;
};
