var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { component, prop } from '../core';
var viewportPreferences;
(function (viewportPreferences) {
    viewportPreferences["activeSM"] = "gb-active-sm";
    viewportPreferences["activeMED"] = "gb-active-med";
    viewportPreferences["activeLG"] = "gb-active-lg";
})(viewportPreferences || (viewportPreferences = {}));
let Expander = class Expander extends HTMLElement {
    constructor() {
        super();
        this.hasClosedHeadline = false;
    }
    onInit() {
        this.expanderButton = this.querySelector('.gb-expander-btn');
        this.expanderHeadline = this.querySelector('.gb-expander-btn .gb-expander-headline');
        this.expanderContent = this.querySelector('.gb-expander-content');
        this.expanderContentBody = this.querySelector('.gb-expander-content-body');
        this.expanderButton.addEventListener('click', () => this.onClick());
        this.expanderButton.addEventListener('keydown', e => this.handleKeydown(e));
        if (this.closedHeadline && this.openHeadline)
            this.hasClosedHeadline = true;
        if (this.hasClosedHeadline) {
            if (!this.isOpen && !this.dataDisabled) {
                this.setHeadlineText(this.closedHeadline);
            }
            else {
                this.setHeadlineText(this.openHeadline);
            }
        }
        if (!this.dataDisabled && this.expanderButton.hasAttribute('aria-expanded')) {
            this.expanderButton.setAttribute('aria-expanded', this.isOpen.toString());
        }
    }
    disconnectedCallback() {
        this.expanderButton.removeEventListener('click', () => this.onClick());
        this.expanderButton.removeEventListener('keydown', e => this.handleKeydown(e));
    }
    handleKeydown(e) {
        if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            this.onClick();
        }
    }
    onClick() {
        if (!this.dataDisabled) {
            if (this.isOpen) {
                this.closeExpander();
            }
            else {
                this.openExpander();
            }
        }
    }
    get isOpen() {
        return (this.classList.contains('active') || window.getComputedStyle(this.expanderContent).visibility === 'visible');
    }
    closeExpander() {
        const expanderContentHeight = this.expanderContentBody.offsetHeight;
        this.expanderContent.style.height = expanderContentHeight + 'px';
        if (this.hasClosedHeadline) {
            this.setHeadlineText(this.closedHeadline);
        }
        setTimeout(() => {
            this.expanderContent.style.height = '';
            this.classList.remove('active', viewportPreferences.activeSM, viewportPreferences.activeMED, viewportPreferences.activeLG);
            this.expanderButton.setAttribute('aria-expanded', 'false');
        }, 0);
    }
    openExpander() {
        const expanderContentHeight = this.expanderContentBody.offsetHeight;
        this.expanderContent.addEventListener('transitionend', () => {
            this.expanderContent.style.height = '';
        }, { once: true });
        if (this.hasClosedHeadline) {
            this.setHeadlineText(this.openHeadline);
        }
        this.classList.add('active');
        this.expanderContent.style.height = expanderContentHeight + 'px';
        this.expanderButton.setAttribute('aria-expanded', 'true');
    }
    setHeadlineText(headlineText) {
        if (headlineText && this.expanderHeadline) {
            this.expanderHeadline.innerText = headlineText;
        }
    }
};
__decorate([
    prop('boolean')
], Expander.prototype, "dataDisabled", void 0);
__decorate([
    prop('string')
], Expander.prototype, "openHeadline", void 0);
__decorate([
    prop('string')
], Expander.prototype, "closedHeadline", void 0);
Expander = __decorate([
    component('gb-expander')
], Expander);
export { Expander };
