var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { GBPE_NAMESPACE } from '../../../models/user-info';
import { mockEnabled } from '../../../config/script-attributes';
import { reportErrorToDynatrace } from '../../../utils/dynatrace-error-logging';
export class PortableAPI {
    constructor(apiData) {
        this._item = apiData.item;
        this._url = apiData.url;
        this._urlList = apiData.urlList;
        this._mock = apiData.mock;
        this._mockEnabled = apiData.mockEnabled || mockEnabled;
        this._bearerToken = apiData.bearerToken;
        this._dependentInstance = apiData.dependentInstance;
        this._storageKey = GBPE_NAMESPACE + this._item.toUpperCase();
        const clsName = this.capitalize(this._item + 'CarbonCopy');
        this[clsName] = class extends CarbonCopy {
        };
        this._carbonCopyClassName = clsName;
        this._carbonCopyInstanceObsoleteResolverStack = [];
    }
    getInterface() {
        return this.buildCarbonCopy();
    }
    _fetchPromiseRedirect() {
        var _a;
        return (_a = this._lookupPromise) === null || _a === void 0 ? void 0 : _a.then(() => this.buildCarbonCopy());
    }
    capitalize(inputString) {
        return inputString.slice(0, 1).toUpperCase() + inputString.slice(1, inputString.length);
    }
    requestParameters() {
        const bearer = this._bearerToken ? 'Bearer ' : '';
        if (this._bearerToken) {
        }
        const headers = {
            Authorization: `${bearer + window.gbpe.auth.idToken}`,
            locale: window.gbpe.config.locale,
            region: window.gbpe.config.region,
        };
        const param = {
            method: 'GET',
            headers,
        };
        return param;
    }
    getMockData() {
        return resolve => setTimeout(() => {
            resolve({
                ok: 1,
                status: 200,
                json: () => this._mock,
            });
        }, 200);
    }
    _multiFetchApiCall() {
        const promises = [];
        const jsonResponse = [];
        this._lookupPromise = new Promise((resolve, reject) => {
            for (let i = 0; i < this._urlList.length; i++) {
                promises.push((this._mockEnabled
                    ? new Promise(this.getMockData())
                    : fetch(this._urlList[i], this.requestParameters()))
                    .then(resp => {
                    if (resp && resp['status'] === 200) {
                        return resp;
                    }
                    throw this.createError(`Network error - ${this === null || this === void 0 ? void 0 : this._urlList[i]}`);
                })
                    .catch(() => {
                    throw this.createError(`Network error - ${this === null || this === void 0 ? void 0 : this._urlList[i]}`);
                })
                    .then((resp) => __awaiter(this, void 0, void 0, function* () {
                    var _a;
                    const actionRequiredList = yield resp['json']();
                    (_a = actionRequiredList['actionRequired']) === null || _a === void 0 ? void 0 : _a.forEach(urlValue => {
                        console.log('Api response' + JSON.stringify(urlValue));
                        urlValue.url ? jsonResponse.push(JSON.stringify(urlValue)) : '';
                    });
                }))
                    .catch(error => {
                    console.error(error.message);
                }));
            }
            Promise.all(promises)
                .then(() => __awaiter(this, void 0, void 0, function* () {
                const actionRequiredVal = jsonResponse.length
                    ? `{"actionRequired": [${jsonResponse}]}`
                    : `{"actionRequired": []}`;
                resolve(sessionStorage.setItem(this._storageKey, actionRequiredVal));
                console.log('API response consolidated storage object' + actionRequiredVal);
                document.dispatchEvent(new CustomEvent('gb-sessionStorageRefreshed'));
                this._lookupPromise = null;
                console.log('API call for item :' + this._item);
            }))
                .catch(error => {
                this.createError(error);
                reject(error);
            });
        });
    }
    buildCarbonCopy() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            yield window.gbpe.auth.loaded;
            if (window.gbpe.auth.currentAuthState !== 1) {
                return Promise.reject('User is unauthenticated !!!');
            }
            if (this._url === '' || this._url == undefined) {
                return Promise.resolve(`${this._item.toUpperCase()} Disabled for this Navigation: Cancelling API Call...`);
            }
            if (this._lookupPromise) {
                return this._fetchPromiseRedirect();
            }
            const cache = sessionStorage.getItem(this._storageKey);
            if (cache === null) {
                if (this._urlList && ((_a = this._item) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes('missinginfo'))) {
                    this._multiFetchApiCall();
                }
                else {
                    this._lookupPromise = new Promise((resolve, reject) => {
                        this._fetchPromise = (this._mockEnabled ? new Promise(this.getMockData()) : fetch(this._url, this.requestParameters()))
                            .then(resp => {
                            if (resp && (resp['status'] === 200 || resp['status'] === 201)) {
                                return resp;
                            }
                            throw this.createError(`Network error ${this === null || this === void 0 ? void 0 : this._url}`);
                        })
                            .catch(() => {
                            throw this.createError(`Network error ${this === null || this === void 0 ? void 0 : this._url}`);
                        })
                            .then((resp) => __awaiter(this, void 0, void 0, function* () {
                            resolve(sessionStorage.setItem(this._storageKey, JSON.stringify(yield resp['json']())));
                            document.dispatchEvent(new CustomEvent('gb-sessionStorageRefreshed'));
                            this._lookupPromise = null;
                            console.log('API call for item :' + this._item);
                        }))
                            .catch(error => {
                            this.createError(error);
                            reject(error);
                        });
                    });
                }
                return this._fetchPromiseRedirect();
            }
            console.log('creating copy of item from cache :' + this._item);
            this._carbonCopyInstanceObsoleteResolverStack = [];
            const ccInstance = new this[this._carbonCopyClassName](this, this.safeJsonParse(cache), this._dependentInstance);
            return Promise.resolve(ccInstance);
        });
    }
    trackPendingCarbonCopyInstanceObsoleteResolver(resolver) {
        this._carbonCopyInstanceObsoleteResolverStack.push(resolver);
    }
    reset() {
        const stack = this._carbonCopyInstanceObsoleteResolverStack;
        sessionStorage.removeItem(this._storageKey);
        stack.map(resolver => resolver());
        stack.splice(0, stack.length);
    }
    createError(msg = 'Global Brands Portable Experiences', name = 'GBPE' + '-Error') {
        this._lookupPromise = null;
        const e = new Error(msg);
        e.name = name;
        reportErrorToDynatrace(e);
        return e;
    }
    retryAPICall(callPath, tries, waitTime, error, failedMessage) {
        return __awaiter(this, void 0, void 0, function* () {
            let callLoopCount = 0;
            let responseStatus;
            while (callLoopCount < tries) {
                try {
                    const response = fetch(callPath, this.requestParameters())
                        .then(response => response.json())
                        .catch(err => {
                        throw err;
                    });
                    responseStatus = response['status'];
                    callLoopCount++;
                }
                catch (_a) {
                    throw error;
                }
                finally {
                    if (responseStatus === 200) {
                        return true;
                    }
                    else {
                        if (failedMessage) {
                            console.log(failedMessage + ' (' + responseStatus + ')');
                        }
                        if (callLoopCount === tries) {
                            break;
                        }
                        yield new Promise(resolve => setTimeout(resolve, waitTime));
                    }
                }
            }
            return false;
        });
    }
    safeJsonParse(inputString, errResp = {}) {
        try {
            return JSON.parse(inputString);
        }
        catch (e) {
            console.error(this.createError('JSON Parse Error - Unexpected Input'));
            return errResp;
        }
    }
}
export class CarbonCopy {
    constructor(portableAPI, data, dependency) {
        this.reset = () => portableAPI.reset();
        this.assignData = data => {
            this['data'] = data;
            delete this.assignData;
        };
        this.assignData(data);
        dependency ? Object.assign(this, dependency) : '';
        this.whenObsolete = new Promise(resolver => {
            portableAPI.trackPendingCarbonCopyInstanceObsoleteResolver(resolver);
        }).then(() => {
            console.log('Reset called for item:' + portableAPI._item);
            document.dispatchEvent(new CustomEvent('gb-myaccountReset', { detail: { itemName: portableAPI._item } }));
        });
    }
}
