var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { clearUserInfo } from '../config/auth-enumerations';
export class LanguageService {
    constructor(_urlConfig) {
        this._urlConfig = _urlConfig;
        this._initialized = false;
        this.handleLanguageLinks = (globalNav) => __awaiter(this, void 0, void 0, function* () {
            yield window.gbpe.auth.loaded;
            const languageLinkList = Array.from(globalNav.shadowRoot.querySelectorAll('a[mporgnav]'));
            languageLinkList.forEach(languageLink => {
                languageLink.onclick = () => {
                    const languageCode = languageLink.getAttribute('data-lang');
                    if (languageCode && languageCode !== '') {
                        window.gbpe.languageService.toggleLanguage(languageCode.toUpperCase());
                    }
                    return false;
                };
            });
        });
    }
    init(languages) {
        if (!this._initialized) {
            this._availableLanguages = languages;
            for (const lang in this._availableLanguages) {
                if (this._availableLanguages.hasOwnProperty(lang) && this._availableLanguages[lang].default === true) {
                    this._currentLang = this._availableLanguages[lang];
                    break;
                }
            }
            if (this._currentLang == undefined &&
                this._availableLanguages != undefined &&
                Object.keys(this._availableLanguages).length >= 1) {
                const firstKey = Object.keys(this._availableLanguages)[0];
                this._currentLang = this._availableLanguages[firstKey];
            }
            this._initialized = true;
        }
    }
    get currentLanguage() {
        return this._currentLang;
    }
    get availableLanguages() {
        if (this._initialized) {
            return this._availableLanguages;
        }
        else {
            return undefined;
        }
    }
    addLanguage(lang) {
        if (this._initialized) {
            if (lang !== null && lang !== undefined) {
                let langExists = false;
                for (const key in Object.keys(this._availableLanguages)) {
                    if (this._availableLanguages[key] === lang) {
                        langExists = true;
                        break;
                    }
                }
                if (!langExists) {
                    this._availableLanguages[lang.languageCode.toUpperCase()] = lang;
                    if (this._currentLang === undefined || this._currentLang === null) {
                        this._currentLang = this._availableLanguages[lang.languageCode.toUpperCase()];
                    }
                }
                else {
                    window.console.log('Language already exists in available languages');
                }
            }
        }
        else {
            window.console.error('Language Service has not been initialized yet');
        }
    }
    toggleLanguage(lang) {
        if (this._initialized) {
            if (this._availableLanguages.hasOwnProperty(lang.toUpperCase())) {
                if (this._availableLanguages[lang.toUpperCase()] === this._currentLang) {
                    window.console.log(`Language ${lang} is currently selected`);
                }
                else {
                    const currentPath = window.location.pathname + window.location.search + window.location.hash;
                    const langCode = this._availableLanguages[lang.toUpperCase()].languageCode;
                    const currentLangCode = this._currentLang.languageCode;
                    const langRegex = new RegExp(`\/([a-z]{2}-)?(${currentLangCode})`, 'i');
                    let currentLangCodeFromPath = currentPath.match(langRegex)[0].slice(1);
                    let updatedPath = currentPath.replace(langRegex, `/$1${langCode}`);
                    if (updatedPath.endsWith(`/${currentLangCodeFromPath}`)) {
                        updatedPath =
                            updatedPath.substr(0, updatedPath.lastIndexOf(`/${currentLangCodeFromPath}`)) +
                                `/${currentLangCodeFromPath.replace(langRegex, `$1${langCode}`)}`;
                    }
                    this._currentLang = this._availableLanguages[lang.toUpperCase()];
                    currentLangCodeFromPath = currentLangCodeFromPath.replace(`${currentLangCode}`, `${langCode}`);
                    clearUserInfo(this._urlConfig);
                    const langPattern = '/' + currentLangCodeFromPath;
                    const domainURL = this._currentLang.url.endsWith('/')
                        ? this._currentLang.url.substring(0, this._currentLang.url.length - 1)
                        : this._currentLang.url;
                    if (updatedPath.startsWith(langPattern) && this._currentLang.url.endsWith(langPattern)) {
                        const startCodePattern = '/' + currentLangCodeFromPath + '/';
                        const startingHashPattern = '/' + currentLangCodeFromPath + '#';
                        const startingQueryPattern = '/' + currentLangCodeFromPath + '?';
                        const startingExtensionPattern = '/' + currentLangCodeFromPath + '.';
                        if (updatedPath.startsWith(startCodePattern) ||
                            updatedPath.startsWith(startingHashPattern) ||
                            updatedPath.startsWith(startingQueryPattern) ||
                            updatedPath.startsWith(startingExtensionPattern)) {
                            const pathForwardSlashIndex = updatedPath.indexOf('/', 1);
                            updatedPath =
                                pathForwardSlashIndex > -1
                                    ? updatedPath.substr(pathForwardSlashIndex)
                                    : updatedPath.substr(3);
                        }
                        if (updatedPath === `/${currentLangCodeFromPath}`) {
                            updatedPath = '';
                        }
                    }
                    if (updatedPath === '/') {
                        updatedPath = '';
                    }
                    const combinedURL = domainURL + updatedPath;
                    window.location.replace(combinedURL);
                }
            }
            else {
                window.console.error(`Language ${lang} is not available in language service`);
            }
        }
        else {
            window.console.error('Language service has not been initialized');
        }
    }
}
