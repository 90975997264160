import { NavConfig } from './base';
import { MSalProdConfig } from '../../../config/msal-configs/base/msal-config-prod';
export class UnitedStatesConfig extends NavConfig {
    constructor(brand) {
        super();
        this._region = 'us';
        this._language = 'en';
        this._urls = {
            MyAccountProfileURL: '/api/_gbpe/v3/profiles',
            MyAccountRewardsURL: '/api/_gbpe/v3/rewards',
            MyAccountVehicleURL: '/ownercenter/api/garagesummary',
            MyAccountMissingInfoURL: '/ownercenter/api/checkMissingInfo',
            NotificationInboxURL: '/notif/delivery/inbox/v1/customer',
            logoutURL: location.origin,
        };
        this._brand = brand;
        try {
            if (PROD_BUILD) {
                this._auth = {
                    tenant: MSalProdConfig.tenant,
                    authority: MSalProdConfig.authority,
                    policy: MSalProdConfig.policy,
                    clientID: MSalProdConfig.clientID,
                    authScopes: ['openid', 'profile'],
                    approvedAuthorities: MSalProdConfig.approvedAuthorities,
                };
            }
        }
        catch (e) {
            console.error('Error switching over to production configuration in United States Headless Auth', e);
        }
    }
}
